import React from 'react';


import '../MainContent.scss'
import {getParams, redirectTo} from "../../utils/UrlUtil";
import {getSelectedCategoryId, getSelectedStartTime} from "../../services/ChannelService";
import _ from "lodash";
import Select from "react-select";
import {getTime} from "../../utils/Util";

class CategorySelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultOptionValue: {value: undefined, label: "Alla kategorier"}
        }
    }

    getOptions = () => {
        let {defaultOptionValue} = this.state
        let {categories} = this.props;
        let options = categories.map((category) => {
            return {
                value: category.id, label: category.name
            }
        })
        options.unshift(defaultOptionValue);
        return options;
    }
    getSelectedCategory = () => {
        let categoryId = getSelectedCategoryId()
        let option = _.find(this.getOptions(), (option) => {
            return option.value == categoryId
        })
        if (!option) {
            let {defaultOptionValue} = this.state
            option = defaultOptionValue
        }
        return option
    }

    componentDidMount() {
    }

    render() {
        let {onChange} = this.props;
        return (
            <div className="filter">
                <Select value={this.getSelectedCategory()} onChange={(option) => {
                    onChange(option, "category")
                }} options={this.getOptions()} menuPlacement="top"/>

            </div>
        )
    }
}

export default CategorySelector