import React from 'react'
import MyFavouritePrograms from "../program/MyFavouritePrograms";
import IsLoggedIn from "../user/IsLoggedIn";
import LoginHeader from "./LoginHeader";
import UserMenu from "./UserMenu";
import SideBar from "../side-bar/SideBar";

class FavouritePrograms extends React.Component {
    render() {
        return (
            <div className="main-content">
                <div className="container">
                    <UserMenu/>
                    <div className="row">
                        <div className="col-12">
                            <div className="user-container">
                                <MyFavouritePrograms/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default FavouritePrograms
