import React from 'react';


import '../MainContent.scss'
import {getParams, redirectTo} from "../../utils/UrlUtil";
import {getSelectedChannelId} from "../../services/ChannelService";
import _ from "lodash";
import Select from 'react-select'
import {getChannelUrl} from "../../utils/Util";

class ChannelSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultOptionValue: {value: undefined, label: "Välj kanal..."}
        }
    }

    getOptions = () => {
        let {defaultOptionValue} = this.state
        let {channels} = this.props;
        channels = _.sortBy(channels, "tabIndex");
        let options = channels.map((channel) => {
            return {
                value: channel.id, label: channel.name
            }
        })
        options.unshift(defaultOptionValue);
        return options;
    }
    getSelectedChannel = () => {
        let channelId = getSelectedChannelId()
        let option = _.find(this.getOptions(), (option) => {
            return option.value == channelId
        })
        if (!option) {
            let {defaultOptionValue} = this.state
            option = defaultOptionValue
        }
        return option
    }


    componentDidMount() {
    }

    render() {
        let {onChange} = this.props;
        return (
            <div className="filter">
                <Select value={this.getSelectedChannel()} onChange={(option) => {
                    onChange(option, "channel")
                }} options={this.getOptions()} menuPlacement="top"/></div>
        )
    }
}

export default ChannelSelector