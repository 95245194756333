import React from 'react';
import MyFavouritePrograms from "./program/MyFavouritePrograms";


import './MainContent.scss'
import {
    getChannelUrl,
    isArticleDetailPage,
    isArticleListPage,
    isChannelSearchPage,
    isFilmPage,
    isSeriesPage,
    isSportsPage,
    isStreamDetail,
    isStreamPage
} from "../utils/Util";
import {getParams, redirectTo, toQueryString} from "../utils/UrlUtil";
import _ from 'lodash'
import {isMobile} from "react-device-detect";
import Search from "./Search";
import http from "../services/HttpService";
import ChannelSelector from "./misc/ChannelSelector";
import TimeSelector from "./misc/TimeSelector";
import CategorySelector from "./misc/CategorySelector";
import {getSelectedStartTime} from "../services/ChannelService";

class InfoBar extends React.Component {
    constructor(props) {
        super(props);
        let params = getParams();
        this.state = {
            channels: [],
            categories: [],
            searchTerm: params.get("q") || params.get("txt") || ''
        }
    }

    onSearchSubmit = searchTerm => {
        let params = getParams();
        if (searchTerm) {
            params.set("q", searchTerm);
        } else {
            params.delete("q")
        }
        if (isArticleListPage() || isArticleDetailPage()) {
            redirectTo("/Artiklar?" + params.toString());
        } else if (isStreamPage() || isStreamDetail()) {
            redirectTo("/Stream?" + params.toString());
        } else {
            redirectTo("/Sok?" + params.toString());
        }
    };

    loadCategories = () => {
        let type, url = "/api/categories/findAllByGenre"
        let params = {}
        if (isFilmPage()) {
            type = 'Film'
        } else if (isSeriesPage()) {
            type = 'Tv-serier'
        } else if (isSportsPage()) {
            type = 'Sport'
        } else {
            url = "/api/categories"
            params.size = 1000;
        }
        if (type) {
            params.type = type;
        }
        http.get(url + toQueryString(params))
            .then((response) => {
                let {categories} = response.data.content;
                this.setState({categories});
            })
            .catch((error) => {
                console.log(error.toJSON)
            })
    }
    loadChannels = () => {
        http.get("/api/channels/cache" + toQueryString({active: true}))
            .then((response) => {
                let {channels} = response.data.content;
                this.setState({channels});
            })
            .catch((error) => {
                console.log(error.toJSON)
            })
    }
    loadChannelsAndCategories = () => {
        let params = {
            q: getParams("q"),
            active: true,
            size: 1000
        };

        http.get("/api/programs/channelsAndCategory" + toQueryString(params))
            .then((response) => {
                let {channels, categories} = response.data.content;
                this.setState({
                    channels,
                    categories
                });
            })
            .catch(function (error) {
                console.log(error.data)
            })
    };
    onChange = (option, param) => {
        let baseUrl = "/Standard"
        if (param === "channel") {
            let {channels} = this.state;
            let selectedChannel = _.find(channels, ["id", option.value])
            redirectTo(getChannelUrl(selectedChannel))
        } else {
            if (option.value) {
                redirectTo(baseUrl + toQueryString({[param]: option.value}))
            } else {
                redirectTo(baseUrl)
            }
        }

    };

    componentDidMount() {
        if (isChannelSearchPage()) {
            this.loadChannelsAndCategories();
        } else {
            this.loadChannels();
            this.loadCategories();
        }
    }


    render() {
        let {searchTerm, categories, channels} = this.state;
        return (
            <div className="row info-bar">
                <div className="col-xs-8 col-md-8"> {_.size(channels) > 0 ?
                    <ChannelSelector channels={channels} onChange={this.onChange}/> : ''}
                    {_.size(categories) > 0 ? <CategorySelector categories={categories} onChange={this.onChange}/> : ''}
                    <TimeSelector onChange={this.onChange} selectedHour={getSelectedStartTime()}/>
                </div>

                <div className={isMobile ? 'col-xs-4 col-md-4 padding0' : 'col-xs-4 col-md-4'}>
                    <Search onFormSubmit={this.onSearchSubmit} searchTerm={searchTerm}/>
                </div>

            </div>
        )
    }
}

export default InfoBar