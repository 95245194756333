import React from "react";
import '../MainContent.scss';
import '../common.scss';
import {
    getChannelLogo, getChannelUrl,
    getDay,
    getMyPrograms,
    getSelectedDay,
    getSelectedWeek,
    isLoggedIn,
    removeMyPrograms
} from "../../utils/Util";
import http from "../../services/HttpService";
import {redirectTo, toQueryString} from "../../utils/UrlUtil";
import _ from 'lodash'
import moment from 'moment'
import ProgramModalContext from '../context/ProgramModalContext';
import ToastUtil from "../../utils/ToastUtil";
import {format} from "../../utils/DateUtil";
import {FaMinusCircle, FaPrint} from 'react-icons/fa';

class MyFavouritePrograms extends React.Component {
    static contextType = ProgramModalContext;

    constructor(props) {
        super(props);
        this.smallLogos = require.context('../../images/logos/small', true, /.png$/);
        this.state = {
            myPrograms: [],
            isInProgress: false,
            selectedDate: moment(),
        }
    }

    removeProgram = (program) => {
        if (isLoggedIn()) {
            let params = {programId: program.id};
            http.get("/api/programs/removeFromMyPrograms" + toQueryString(params))
                .then((response) => {
                    let {status, errors} = response.data;
                    if (status) {
                        ToastUtil.success("Program has been deleted from MyProgram list.");
                        this.loadMyPrograms()
                    } else {
                        alert(errors[0])
                    }
                })
                .catch((error) => {
                    console.log(error.toJSON)
                })
        } else {
            removeMyPrograms(program);
            this.loadMyPrograms()
        }
    };

    getSmallLogo = (sourceId) => {
        try {
            return this.smallLogos('./' + sourceId + ".png").default
        } catch (e) {

        }
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    loadMyPrograms = () => {
        if (isLoggedIn()) {
            this.toggleInProgress(true);
            let params = {day: getDay()};
            http.get("/api/public/programs/me" + toQueryString(params))
                .then((response) => {
                    this.setState({
                        myPrograms: response.data.content.programs
                    });
                })
                .catch((error) => {
                    console.log(error.toJSON)
                })
                .finally(() => {
                    this.toggleInProgress(false)
                })
        } else {
            this.setState({
                myPrograms: getMyPrograms()
            });
        }
    };

    getText = () => {
        let {myPrograms} = this.state;
        if (_.size(myPrograms) === 0) {
            return <span className='text'>Håll koll på dina favoritprogram genom att klicka på ett program och välja "Lägg till i min tablå". Ange om du bara vill lägga till detta sändningstillfälle eller om du även vill visa framtida avsnitt (byt dag i navigeringen för att se dessa). Dina val sparas med cookies. Observera att om du tömmer dina cookies kommer dina val försvinna nästa gång du besöker Dagenstv om du inte skapar ett konto</span>
        }
    };

    componentDidMount() {
        this.setState({selectedDate: moment().add(getDay(), "days")});
        this.loadMyPrograms();
    }

    render() {
        let {myPrograms, selectedDate} = this.state;
        myPrograms = _.sortBy(myPrograms, "startTime");
        return (

            <div className="my-list">
                <h2>
                    Favoritprogram
                    <span className="print-icon">
                            <a href={`/PrintMyPrograms?day=${getSelectedDay()}&week=${getSelectedWeek()}`}
                               target="_blank">   <FaPrint/></a>
                        </span>
                    <br/>
                    {_.size(myPrograms) > 0 ?
                        <span className="small">{selectedDate.format("ddd DD MMMM, yyyy")}</span> : null}
                </h2>
                {_.size(myPrograms) == 0 ? <p>Inga favoriter under valt datum</p> : null}
                <ul>
                    {
                        myPrograms.map((program, index) => {
                            return <li className="clearfix" key={index} onClick={() => {
                                redirectTo(getChannelUrl(program.channel, program))
                            }}>
                                <h3 className="name">{program.name}</h3>
                                <div className="flex">
                                    <span className='channel-logo'>
                                        {getChannelLogo(program.channel, "channel-logo")}
                                    </span>
                                    <div>
                                <span className="name">
                                    {program.name}
                                </span>
                                        <p>
                                            {program.channel.name} {format(program.startTime, "D/M")}
                                            {program.formattedStartTime} ({program.formattedEndTime})
                                        </p>
                                        <span className="remove-icon"><FaMinusCircle onClick={() => {
                                            this.removeProgram(program)
                                        }}/></span>
                                    </div>

                                </div>


                            </li>
                        })
                    }
                </ul>
                {this.getText()}
            </div>

        )
    }
}

export default MyFavouritePrograms;