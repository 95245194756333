import React, {useEffect, useState} from "react";
import http from "../../services/HttpService";
import "./blogListing.scss";
import {format} from "../../utils/DateUtil";
import {Helmet} from "react-helmet";
import BlogRecommendations from "./BlogRecommendations";
import {
    getAdsLabel,
    getHeaderDesktopAds,
    getHighDensityDesktopAds,
    getHighDensityMobileAds,
    hasViewedBlog,
    setHasViewBlog
} from "../../utils/Util";
import {isMobile} from "react-device-detect";
import $ from 'jquery'
import {isDesktop, isMobileDevice, isTableDevice} from "../../utils/DeviceUtil";

const BlogDetail = (props) => {
    const [blog, setBlog] = useState({});

    const setTitleAndMetadata = (blog) => {
        if (blog) {
            return <Helmet>
                <title>{'Dagenstv - ' + blog.title}</title>
                <meta name="description" content={blog.title}/>
            </Helmet>
        }
    };

    const getStructuredData = (blog) => {
        let data = {};
        if (blog) {
            data = {
                "@context": "https://schema.org",
                "@type": "BlogPosting",
                "mainEntityOfPage": {
                    "@type": "WebPage",
                    "@id": `https://www.kolla.tv/artikel/${blog.friendlyUrl}`
                },
                "headline": `${blog.title}`,
                "description": `${blog.teaser}`,
                "image": `${blog.imageUrl}`,
                "author": {
                    "@type": "Person",
                    "name": `${blog.author || 'Peter Grufman'}`
                },
                "publisher": {
                    "@type": "Organization",
                    "name": "Dagenstv",
                    "logo": {
                        "@type": "ImageObject",
                        "url": ""
                    }
                },
                "datePublished": `${format(blog.publishAt, "YYYY-MM-DD")}`
            }
        }
        return JSON.stringify(data);
    };

    const setStructureData = (blog) => {
        if (blog && blog.id) {
            return <Helmet>
                <script type="application/ld+json">{getStructuredData(blog)}</script>
            </Helmet>
        }
    };

    const increaseViewCount = (blog) => {
        if (!hasViewedBlog(blog)) {
            http
                .get("/api/blogs/hasView/" + blog.id)
                .then((response) => {
                    setHasViewBlog(blog);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };

    useEffect(() => {
        const {match: {params}} = props;
        let friendlyUrl = params.friendlyUrl;
        http
            .get("/api/blogs/findByFriendlyUrl/" + friendlyUrl)
            .then((response) => {
                let {blog} = response.data.content;
                setBlog(blog);
                if (blog && blog.id) {
                    increaseViewCount(blog);
                    if (isMobile) {
                        setTimeout(() => {
                            $("video").removeAttr("height");
                        }, 500)
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, []);

    if (blog) {
        return (
            <div className="blog-details">
                {setTitleAndMetadata(blog)}
                {setStructureData(blog)}

                <div className="blog-ads">
                    {getHighDensityDesktopAds()}
                    {getHighDensityMobileAds()}
                    {getHeaderDesktopAds()}
                    {isTableDevice() ? <>{getAdsLabel()}<div id="cncpt-tab_lb1"></div></> : ''}
                </div>

<div className="blog-container">
                <span className="back-btn"><a href={"/Artiklar"} target={"_self"}>{'<'} TILLBAKA</a></span>
                {blog.imageUrl ? <div className="large-image">
                    <img src={blog.imageUrl} width="100%"/>
                </div> : null}

                <h1 className="heading1">{blog.title}<br/></h1>

                <div className="blog-text">
                    <p dangerouslySetInnerHTML={{__html: blog.content}}></p>
                </div>

                <p className="meta-info">
                    {blog.author ? <span>Artikel av {blog.author}</span> : null} {blog.publishAt ?
                    <span>{blog.author ? ',' : null} {format(blog.publishAt, "DD MMMM, YYYY")}</span> : ''}
                </p>

                {/*Display second ads */}
                <div className="blog-ads">
                    {getAdsLabel()}
                    {isDesktop() ? <div id="cncpt-lb2"></div> : ''}
                    {isTableDevice() ? <div id="cncpt-tab_lb2"></div> : ''}
                    {isMobileDevice() ? <div id="cncpt-mob2"></div> : ''}
                </div>

                {blog.category ?
                    <BlogRecommendations excludeId={blog.id} heading={'Från samma kategori'}
                                         category={blog.category.id}/> : ''}

                {/*TODO display third ads*/}
                <div className="blog-ads">
                    {getAdsLabel()}
                    {isDesktop() ? <div id="cncpt-lb3"></div> : ''}
                    {isTableDevice() ? <div id="cncpt-tab_lb3"></div> : ''}
                    {isMobileDevice() ? <div id="cncpt-mob3"></div> : ''}
                </div>


                </div>

            </div>
        );
    } else {
        return <div class="row">
            <div class="col-md-12">
                <div class="error-template text-center">
                    <br/>
                    <br/>
                    <br/>
                    <h1>Oops!</h1>
                    <h2>404 Ej Hittad</h2>
                    <div class="error-details">
                        Tyvärr, begärd sida hittades inte!
                    </div>
                    <br/>
                    <div class="error-actions">
                        <a href="/Artiklar" target="_self" class="btn btn-primary btn">
                            Artiklar
                        </a>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        </div>;
    }
};
export default BlogDetail;
