import React from "react";
import http from "../../services/HttpService";
import {toQueryString} from "../../utils/UrlUtil";

class ChannelList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            channels: []
        };
    }

    loadChannels = () => {
        http.get("/api/channels/cache" + toQueryString({active: true}))
            .then((response) => {
                let {channels} = response.data.content;
                this.setState({channels});
            }).catch((error) => {
            console.log(error)
        });
    }

    componentDidMount() {
        this.loadChannels();
    }

    render() {
        let {channels} = this.state;
        return (
            <div className="channel-list ">
                <div className="col-12 col-sm-12 col-md-12 col-lg-9 paddingLeft0">
                <h2>kanaler</h2>
                <ul>
                    {
                        channels.map((channel, index) => {
                            return <li>
                                <a href={"/kanal/" + channel.friendlyUrl}
                                   title={channel.name}>{channel.name}</a>
                            </li>
                        })
                    }
                </ul>
                </div>
                
            </div>
        )
    }
}

export default ChannelList;
