import React from "react";
import "./Header.scss";
import DesktopNavigation from "../DesktopNavigation";
import logo from "../../images/logo.png";
import {getParams} from "../../utils/UrlUtil";
import Login from "../user/Login";
import MobileNavigation from "../menu/MobileNavigation";
import InfoBar from "../InfoBar";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: getParams("q") || "",
            openMobileMenu: false,
        };
    }

    toggleMobileMenu = () => {
        let {openMobileMenu} = this.state;
        this.setState({openMobileMenu: !openMobileMenu});
    };

    getLogo = () => {
        return <img src={logo} alt={"Dagenstv"}/>;
    };

    openLogin = (evt) => {
        evt.preventDefault();
        this.toggleMobileMenu();
        this.setState({showLogin: true})
    };

    closeLogin = () => {
        this.setState({showLogin: false})
    };

    render() {
        let {openMobileMenu, showLogin} = this.state;
        return (
            <div>
                <header className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 padding0">
                            <h1>
                                <a
                                    href="/"
                                    className="logo"
                                    target="_self"
                                    title="Dagenstv - Din personliga Tvtablå"
                                >
                                    {this.getLogo()}
                                </a>

                            </h1>
                            <button
                                className="btn-toggle"
                                id="menu-toggle"
                                onClick={this.toggleMobileMenu}
                            >
                                <div className="line"></div>
                                <div className="line"></div>
                                <div className="line"></div>
                            </button>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                            <DesktopNavigation/>
                            <div
                                className={
                                    !openMobileMenu ? "mobile-menu" : "mobile-menu menu-expand"
                                }
                            >
                                {openMobileMenu ? <MobileNavigation onClose={this.toggleMobileMenu}
                                                                    openLogin={this.openLogin}/> : null}
                            </div>
                        </div>
                    </div>
                    <InfoBar/>
                    <Login show={showLogin} onHide={this.closeLogin}/>
                </header>
            </div>
        );
    }
}

export default Header;
