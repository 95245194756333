import React from 'react';
import '../header/Header.scss'
import _ from 'lodash'
import IsNotLoggedIn from "../user/IsNotLoggedIn";
import IsLoggedIn from "../user/IsLoggedIn";
import {getParams, toQueryString} from "../../utils/UrlUtil";
import logo from "../../images/logo.png";
import {getCurrentUser} from "../../utils/Util";
import {FaUser} from 'react-icons/fa';
import {Link} from 'react-router-dom';


class MobileNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    getUrlWithParams = (baseUrl) => {
        let urlParams = getParams();
        let params = {};
        if (urlParams.get("day")) {
            params.day = urlParams.get("day")
        }
        if (urlParams.get("week")) {
            params.week = urlParams.get("week")
        }
        if (_.size(params) > 0) {
            return baseUrl + toQueryString(params)
        }
        return baseUrl;
    };
    getLogo = () => {
        return <img src={logo} alt={"Dagenstv"}/>;
    };

    render() {
        let {onClose, openLogin} = this.props;
        return (
            <div>
                <nav className="navbar">
                    <h1>
                        Dagenstv <span className="close-btn" onClick={onClose}></span>
                    </h1>
                    <IsLoggedIn>
                        <div className="user-info">
                            <FaUser/>{getCurrentUser().fullName}
                        </div>
                    </IsLoggedIn>
                    <a className="home" href="/Standard" title="Dagenstv - Tips från oss på filmer">Hem</a>
                    <a className={`films`}
                       href={this.getUrlWithParams("/Film")} title="Dagenstv - Film">Film</a>
                    <a className={`series`}
                       href={this.getUrlWithParams("/Tv-serier")}
                       title="Dagenstv - TV-Serier">Serier</a>
                    <a className={`sport`} href={this.getUrlWithParams("/Sport")}
                       title="Dagenstv - Sport">Sport</a>
                    <a className="" href="/Artiklar" title="Dagenstv - Artiklar">Artiklar</a>
                    <Link to="/ProgramJagFoljer">Program jag följer</Link>
                    <IsLoggedIn>
                        <a href="/Installningar" title="Dagenstv - Register">Inställningar</a>
                        <a href="/logout" title="Dagenstv - Register">Logga ut</a>
                    </IsLoggedIn>
                    <IsNotLoggedIn>
                        <a className="" href="/login" onClick={openLogin} title="Dagenstv - Login">Logga in</a>
                    </IsNotLoggedIn>
                </nav>
            </div>
        )
    }
}

export default MobileNavigation