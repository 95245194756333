import React from 'react';


import '../MainContent.scss'
import {getSelectedStartTime} from "../../services/ChannelService";
import {getTime} from "../../utils/Util";
import Select from "react-select";
import _ from 'lodash'

class TimeSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultOptionValue: {value: undefined, label: "Välj tid..."},
            selectedOption: {}
        }
    }

    getOptions = () => {
        let {defaultOptionValue} = this.state
        let options = getTime().map((time) => {
            return {value: time.time, label: time.label}
        })
        options.unshift(defaultOptionValue)
        return options;
    }

    getSelectedTime = () => {
        let {selectedOption} = this.state
        let {selectedHour} = this.props
        let startTime = selectedHour
        if (!startTime) {
            startTime = selectedOption.value;
        }
        let option = _.find(getTime(), (time) => {
            return time.time == startTime
        })
        if (!option) {
            let {defaultOptionValue} = this.state
            option = defaultOptionValue
        }
        return option
    }

    onChange = (option) => {
        let {onChange} = this.props;
        onChange(option, "startTime")
        this.setState({selectedOption: option});
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="filter">
                <Select value={this.getSelectedTime()} onChange={this.onChange}
                        options={this.getOptions()} menuPlacement="top"/>
            </div>
        )
    }
}

export default TimeSelector