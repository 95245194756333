import React from "react";
import Programs from '../program/Programs';
import '../MainContent.scss';
import '../../components/common.scss';
import '../../components/program/ProgramDetail.scss';
import '../program/TableLayout.scss'
import http from '../../services/HttpService'
import {getParams, toQueryString} from '../../utils/UrlUtil'
import _ from 'lodash'
import {
    CHANNEL_CHUNK_SIZE,
    MOBILE_CHANNEL_CHUNK_SIZE,
    TABLET_CHANNEL_CHUNK_SIZE,
    TOTAL_WEEK_DAYS
} from "../../constants/constants";
import {FaPrint, FaSearch} from 'react-icons/fa';

import {Link} from 'react-router-dom';


import {
    getAdsLabel,
    getChannelLogo,
    getChannelUrl, getCurrentUser,
    getProgramClasses,
    getSelectedDate,
    getSelectedDay,
    getSelectedWeek,
    getType,
    hasParam,
    isChannelDetailPage,
    isChannelSearchPage,
    isCompactView,
    isFilmPage,
    isJustNow,
    isLoggedIn, isMyTVShow,
    isSeriesPage,
    isSportsPage, removeLastChar
} from "../../utils/Util";
import ProgramModalContext from '../context/ProgramModalContext';
import {
    doUpdateChannelsOrder,
    getChannelsOrder,
    getIncludeChannels,
    getMyChannelSettings,
    getSelectedStartTime,
    isCategorySelected,
    isChannelSelected
} from '../../services/ChannelService'
import loader from '../../images/loaderLarge.gif'
import {Helmet} from "react-helmet";
import '../channel/channel.scss';
import {isDesktop, isMobileDevice, isTableDevice} from "../../utils/DeviceUtil";
import WeekDays from "../WeekDays";
import SubNav from "../subNav";
import {toDuration} from "../../utils/DateUtil";
import ProgramActions from "../program/ProgramActions";

class Channels extends React.Component {
    static contextType = ProgramModalContext;

    constructor(props) {
        super(props);
        let channels = [];
        let params = getParams();
        for (let i = 0; i <= 120; i++) {
            channels.push({programs: []})
        }
        this.state = {
            channels: channels,
            selectedProgram: {},
            selectedChannel: {},
            selectedCategory: {},
            showProgramDetail: false,
            hasMoreChannels: true,
            myChannelSettings: {},
            rowScrollMargin: 0,
            searchChannels: [],
            searchCategories: [],
            q: params.get("q") || params.get("txt"),
            startTime: params.get("startTime") || params.get("sta"),
            event: params.get("event")
        };
    }

    onChannelSettingsUpdate = (myChannelSettings) => {
        if (isLoggedIn()) {
            this.setState({myChannelSettings: myChannelSettings});
        } else {
            this.forceUpdate();
        }
    };

    _getChannelsOrder = () => {
        if (isLoggedIn()) {
            return this.state.myChannelSettings.orders;
        } else {
            return getChannelsOrder()
        }
    };

    loadChannelWithPrograms = (page, includeChannels) => {
        let selectedDay = getSelectedDay();
        let params = getParams();
        let selectedWeek = getSelectedWeek();
        let type = getType();
        let day = (selectedWeek - 1) * TOTAL_WEEK_DAYS + selectedDay;
        let {channelFriendlyUrl} = this.props;
        params.set("page", page);
        params.set("limit", 12)
        params.set("day", day);
        params.set("active", true);

        if (type) {
            params.set("type", type)
        }
        if (!(this.isSearchLayout() || isMyTVShow())) {
            params.set("hideExtras", true)
        }
        if (_.size(includeChannels) > 0 && !(isChannelSearchPage() || isChannelDetailPage())) {
            params.set("includeChannels", includeChannels);
        }

        if (params.get("q")) {
            params.set("q", params.get("q"))
        } else if (params.get("txt")) {
            params.set("q", params.get("txt"))
        }

        if (params.get("category")) {
            params.set("category", params.get("category"))
        } else if (params.get("cat")) {
            params.set("dagensCategory", params.get("cat"))
        }

        if (params.get("sta")) {
            params.set("startTime", params.get("sta"))
        }

        if (params.get("channel")) {
            params.set("channel", params.get("channel"))
        } else if (params.get("cha")) {
            params.set("dagensChannel", params.get("cha"))
        }

        if (channelFriendlyUrl) {
            params.set("channelFriendlyUrl", channelFriendlyUrl.replace(new RegExp('.asp$'), ''))
        }

        if (isJustNow()) {
            params.set("now", true);
        }
        if (isMyTVShow() && isLoggedIn()) {
            let user = getCurrentUser()
            if (user && user.watchStartHour && user.watchEndHour) {
                params.set("startTime", user.watchStartHour)
                params.set("endTime", user.watchEndHour)
            }
        }

        http.get("/api/es/channels/listWithPrograms?" + params.toString())
            .then((response) => {
                let {channels, hasMoreElements} = response.data.content;
                if (!_.some(this.state.channels, "id")) {
                    this.state.channels = [];
                }
                _.forEach(channels, (channel) => {
                    channel.rowScrollMargin = this.state.rowScrollMargin;
                });
                this.setState({
                    channels: _.concat(this.state.channels, channels),
                    hasMoreChannels: hasMoreElements
                });
                if (hasMoreElements) {
                    this.loadChannelWithPrograms(page + 1, includeChannels)
                }
            })
            .catch(function (error) {
                console.log(error.data)
            })
    };

    loadChannelsAndCategories = () => {
        let params = {
            q: getParams("q"),
            active: true
        };

        http.get("/api/programs/channelsAndCategory" + toQueryString(params))
            .then((response) => {
                let {channels, categories} = response.data.content;
                this.setState({
                    searchChannels: channels,
                    searchCategories: categories
                });
            })
            .catch(function (error) {
                console.log(error.data)
            })
    };

    loadSelectedCategory = async () => {
        let params = getParams();
        if (isCategorySelected()) {
            let queryParams = {}
            if (params.get("category")) {
                queryParams.id = params.get("category");
            } else if (params.get("cat")) {
                queryParams.dagensId = params.get("cat");
            }
            let response = await http.get("/api/categories/findByIdOrDagensId" + toQueryString(queryParams))
            let {category} = response.data.content
            this.setState({selectedCategory: category})
        }
    }


    componentDidMount() {
        let includeChannels = _.map(getIncludeChannels(), "id");
        this.loadSelectedCategory();
        if (isChannelSearchPage()) {
            this.loadChannelsAndCategories();
        }
        if (isLoggedIn()) {
            getMyChannelSettings((myChannelSettings) => {
                this.onChannelSettingsUpdate(myChannelSettings);
                if (myChannelSettings.includeChannelIds) {
                    includeChannels = myChannelSettings.includeChannelIds;
                }
                this.loadChannelWithPrograms(0, includeChannels);
            });
        } else {
            this.loadChannelWithPrograms(0, includeChannels);
        }
    }

    getHeading = () => {
        let heading;
        let title;
        if (isFilmPage()) {
            heading = 'Film på TV'
            title = 'Här hittar du alla tider och kanaler för film på TV'
        } else if (isSportsPage()) {
            heading = 'Sport på TV'
            title = 'Här hittar du alla tider och kanaler för sport på TV'
        } else if (isSeriesPage()) {
            heading = 'Serier på TV'
            title = 'Här hittar du alla tider och kanaler för serier på TV'
        }
        if (heading && title) {
            return <div className={'program-type-heading'}>
                <h1>{heading}</h1>
                <p>{title}</p>
            </div>
        }
    }

    tableLayout = () => {
        let allChannels = this.state.channels;
        let {hasMoreChannels} = this.state;
        doUpdateChannelsOrder(allChannels, this._getChannelsOrder());
        allChannels = _.sortBy(allChannels, "tabIndex");
        let screenWidth = window.screen.width;
        let channelChunkSize = CHANNEL_CHUNK_SIZE;
        if (isMobileDevice()) {
            channelChunkSize = MOBILE_CHANNEL_CHUNK_SIZE;
        } else if (isTableDevice() && screenWidth >= 768 && screenWidth <= 970) {
            channelChunkSize = TABLET_CHANNEL_CHUNK_SIZE
        }
        return (<div>
                <SubNav/>
                {this.getHeading()}
                {
                    _.chunk(allChannels, channelChunkSize).map((channels, parentIndex) => {
                        return <div key={parentIndex}>
                            <ul className={`${parentIndex === 0 ? 'first-section' : ''} program-list row`}>{
                                channels.map((channel, index) => {
                                    return (
                                        <li key={index * (parentIndex + 1)}
                                            className="channel col-6 col-sm-6 col-md-4 col-lg-4 item">
                                            <div className="top-logo">
                                                {getChannelLogo(channel, "channel-logo")}
                                                <h2 className="name">
                                                    <a href={getChannelUrl(channel)}
                                                       title={channel.name}>{channel.name}</a>
                                                </h2>
                                            </div>
                                            <Programs channel={channel}
                                                      onlyNonExpired={isCompactView()}
                                                      programs={channel.programs}
                                                      initSize={isCompactView() ? 6 : 0}/>
                                        </li>
                                    )
                                })
                            }
                            </ul>
                            {/*Display second ads */}
                            {parentIndex === 0 ? <div className="grid-add-container">
                                {getAdsLabel()}
                                {isDesktop() ? <div id="cncpt-lb2"></div> : ''}
                                {isTableDevice() ? <div id="cncpt-tab_lb2"></div> : ''}
                                {isMobileDevice() ? <div id="cncpt-mob2"></div> : ''}
                            </div> : null}

                            {/*TODO display third ads*/}
                            {parentIndex === 3 || !hasMoreChannels && parentIndex > 0 && _.size(allChannels) < channelChunkSize * 4 ?
                                <div className="grid-add-container sticky-ads3">
                                    {getAdsLabel()}
                                    {isDesktop() ? <div id="cncpt-lb3"></div> : ''}
                                    {isTableDevice() ? <div id="cncpt-tab_lb3"></div> : ''}
                                    {isMobileDevice() ? <div id="cncpt-mob3" className="desktopNone"></div> : ''}
                                </div> : null}
                        </div>
                    })
                }

            </div>
        )
    };

    myTvShowLayout = () => {
        let {q, channels, hasMoreChannels, event} = this.state;
        let programs = _.orderBy(_.flatten(channels.map(channel => {
            return channel.programs
        })), "startTime")
        return <div className="search-result">
            <ul className="search-list">
                {
                    programs.map((program, index) => {
                        return <li index={index} id={`${program.id}`}
                                   className={`${event == program.id ? 'selected' : ''}`}>
                            <div className={`flex`}>
                                <span className="logo">{getChannelLogo(program.channel)}</span>
                                <div>
                                    <div>{program.formattedStartTime} ({program.formattedEndTime})</div>
                                    <div>
                                        <strong>{program.name}</strong>
                                        &nbsp;[{program.seasonLabel ? (
                                        <span>Säsong: {program.seasonLabel} | </span>
                                    ) : null}
                                        {program.productionYear ? (
                                            <span> Produktionsår: {program.productionYear} | </span>) : null}
                                        {program.episodeNumber || program.totalEpisode ? (
                                            <span>Avsnitt: {program.episodeNumber}{" "}
                                                {program.totalEpisode ? "/ " + program.totalEpisode : null}
            </span>
                                        ) : null}
                                        {program.repeat ? <span> | Repris </span> : ''}
                                        ]
                                    </div>
                                </div>
                                <ProgramActions program={program} channel={program.channel}/>

                            </div>
                            <p>{program.description}</p>
                            <div>
                                {program.director ?
                                    <span><strong>Regissör:</strong> {removeLastChar(program.director)}</span> : null}
                            </div>
                            <div>
                                {program.actor ?
                                    <span><strong>Skådespelare:</strong> {removeLastChar(program.actor)}</span> : null}
                            </div>
                            <div>{program.commentator ?
                                <span>Kommentator: {removeLastChar(program.commentator)}</span> : null}</div>
                            <div>{program.host ?
                                <span>Programledare: {removeLastChar(program.host)}</span> : null}</div>
                        </li>
                    })

                }
            </ul>
            {!hasMoreChannels && isChannelSearchPage() ?
                <p className="not-found-message"><FaSearch/>Vi hittade inget det du sökt på <strong>{q}</strong>
                </p> : null}
        </div>
    };

    searchLayout = () => {
        let {q, startTime, channels, hasMoreChannels, event} = this.state;
        let {channelFriendlyUrl} = this.props;
        return <div className="search-result">
            {isChannelSearchPage() ? <h2 className="heading">Sökresultat : {q}</h2> : ''}
            {isJustNow() ? <h2 className="heading">På TV just nu</h2> : ''}
            {startTime ? <h2 className="heading">På TV kl. {toDuration(startTime) + ":00"}</h2> : ''}
            {/* {isChannelDetailPage() && isDesktop() ? <WeekDays/> : null} */}
            {isChannelDetailPage() ?
                <Link
                    to={`/PrintChannelPrograms/${channelFriendlyUrl}?day=${getSelectedDay()}&week=${getSelectedWeek()}`}
                    target="_blank" className="print-btn"> Skriv ut lista <FaPrint/> </Link> : null}
            <ul className="search-list">
                {
                    channels.map((channel, channelIndex) => {
                        return _.sortBy(channel.programs, ["startTime"]).map((program, index) => {
                            return <li index={index} id={`${program.id}`}
                                       className={`${event == program.id ? 'selected' : ''} ${getProgramClasses(program)}`}>
                                <div className={`flex`}>
                                    <span className="logo">{getChannelLogo(channel)}</span>
                                    <div>
                                        <div>{program.formattedStartTime} ({program.formattedEndTime})</div>
                                        <div>
                                            <strong>{program.name}</strong>
                                            &nbsp;[{program.seasonLabel ? (
                                            <span>Säsong: {program.seasonLabel} | </span>
                                        ) : null}
                                            {program.productionYear ? (
                                                <span> Produktionsår: {program.productionYear} | </span>) : null}
                                            {program.episodeNumber || program.totalEpisode ? (
                                                <span>Avsnitt: {program.episodeNumber}{" "}
                                                    {program.totalEpisode ? "/ " + program.totalEpisode : null}
            </span>
                                            ) : null}
                                            {program.repeat ? <span> | Repris </span> : ''}
                                            ]
                                        </div>
                                    </div>
                                    <ProgramActions program={program} channel={channel}/>

                                </div>
                                <p>{program.description}</p>
                                <div>
                                    {program.director ?
                                        <span><strong>Regissör:</strong> {removeLastChar(program.director)}</span> : null}
                                </div>
                                <div>
                                    {program.actor ?
                                        <span><strong>Skådespelare:</strong> {removeLastChar(program.actor)}</span> : null}
                                </div>
                                <div>{program.commentator ?
                                    <span>Kommentator: {removeLastChar(program.commentator)}</span> : null}</div>
                                <div>{program.host ?
                                    <span>Programledare: {removeLastChar(program.host)}</span> : null}</div>
                            </li>
                        });
                    })
                }
            </ul>
            {!hasMoreChannels && isChannelSearchPage() ?
                <p className="not-found-message"><FaSearch/>Vi hittade inget det du sökt på <strong>{q}</strong>
                </p> : null}
        </div>
    };

    isSearchLayout = () => {
        return isChannelDetailPage() || isChannelSearchPage() || isJustNow() || hasParam("startTime");
    }

    getChannelList = () => {
        if (isMyTVShow()) {
            return this.myTvShowLayout()
        }
        if (this.isSearchLayout()) {
            return this.searchLayout()
        }
        if (_.size(this.state.channels) > 0) {
            return this.tableLayout()
        }
    };

    getChannelListInfo = () => {
        if (_.size(this.state.channels) === 0 || isChannelSearchPage()) {
            return (
                <div className="channel-list-info clearfix">
                    <span className="icon"></span>
                    <p>Dagenstv erbjuder tablåinformation för över 170 kanaler. Ovan visas ett urval av dessa. Vill
                        du
                        se
                        samtliga kanaler gå till “Mina kanaler”. Under denna sida kan du även anpassa hur din
                        startsida
                        på
                        Dagenstv</p>
                </div>
            )
        }
    };

    getChannelLoader = () => {
        if (this.state.hasMoreChannels) {
            return <div className=" text-center">
                <img src={loader} alt=" loader"/>
            </div>
        }
    };

    setTitleAndMetadata = () => {
        let params = getParams();
        let selectedDate = getSelectedDate().format("dddd, DD MMM yyyy");
        let title = `Tv-program ${selectedDate} | DagensTv.com`;
        let description = "Sveriges tv guide. Film, sport och tv serier. tvtablå, tvguide med 150 kanaler";
        let keywords = "tv, TV, tv tablå, tv guide, tv program, tv sport, tv serier, tv-serier, på tv, på TV , på tv idag, filmer på tv, sport på tv, tv1000, canal+, svt1, svt2, tv3, tv4, kanal 5, tv6, Svenska tv-serier";
        let {selectedCategory} = this.state;
        if (isFilmPage()) {
            title = `Film ${selectedDate} | DagensTv.com`;
            keywords = "tv, TV, film, tv film, film kväll, film i kväll, film imorgon, allt om film, film kanaler, film tv1000, film canal+";
            description = "Film på tv. Se vad som visas";
        } else if (isSeriesPage()) {
            title = `TV-SERIER ${selectedDate} | DagensTv.com`;
            keywords = "tv serie,tv serier,tv serien,vänner tv serie,advokaterna tv serie,alla tv serier,amerikansk tv serie,amerikansk tv serier,amerikanska tv serier";
            description = "Se vilken tv-serie som går på tv";
        } else if (isChannelSearchPage()) {
            title = `TV-Program under sökresultatet för ${params.get("q") || params.get("txt") || ''} | DagensTv.com`;
        } else if (isSportsPage()) {
            title = `Sport ${selectedDate} | DagensTv.com`;
            keywords = "tv, TV, sport, sport tv, tv match, fotboll tv, ishockey tv, allsvenskan tv, premierleague tv,fotbollskanalen, hockeykanalen, nyhetskanalen, hockeymatch, fotbollsmatch";
            description = "Sport på tv. Vi visar vad som går på tv ikväll";
        } else if (isChannelDetailPage() || isChannelSelected()) {
            let {channels} = this.state;
            if (_.size(channels) === 1) {
                let channel = channels[0];
                title = `Tv tablå ${channel.name} ${selectedDate} | DagensTv.com`;
                if (_.size(channel.programs) > 0) {
                    keywords = _.map(channel.programs, "name").join(", ")
                }
            }
        } else if (isCategorySelected() && selectedCategory) {
            title = `Tv tablå ${selectedCategory.name} ${selectedDate} | DagensTv.com`;
        } else if (getSelectedStartTime()) {
            title = `PÅ TV IDAG FÖR ALLA TV KANALER | DagensTv.com | DagensTv.com`;
            keywords = "tv, TV, tv idag, idag tv, på tv idag"
            description = "Läs mer om vad som visas på tv idag"
        }
        return <Helmet>
            <title>{title}</title>
            <meta name="keywords" content={keywords}/>
            <meta name="description" content={description}/>
        </Helmet>
    };

    render() {
        return <div>
            {this.setTitleAndMetadata()}
            {this.getChannelList()}
            {this.getChannelLoader()}
            {this.getChannelListInfo()}
        </div>
    }
}

export default Channels;