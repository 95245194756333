import React from 'react';
import {FaArrowDown, FaArrowUp, FaBars} from 'react-icons/fa';
import http from "../../services/HttpService";
import AddToList from './AddToList'
import '../../style/logoSpritsheet.scss'
import {getChannelLogo, isLoggedIn} from "../../utils/Util";
import {
    addChannelsOrder,
    addToIncludeChannels,
    doUpdateChannelsOrder,
    getChannelsOrder, getIncludeChannels,
    getMyChannelSettings,
    isChannelIncluded,
    removeFromIncludeChannels,
    saveMyChannelsOrder
} from '../../services/ChannelService'
import loader from '../../images/loaderLarge.gif'
import {swap} from "../../utils/ArrayUtil";
import _ from 'lodash'
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import LoginHeader from "./LoginHeader";
import ToastUtil from "../../utils/ToastUtil";
import {toQueryString} from "../../utils/UrlUtil";
import UserMenu from "./UserMenu";
import SideBar from "../side-bar/SideBar";


class MyChannels extends React.Component {
    constructor(props) {
        super(props);
        this.state = {channels: [], activeChannels: [], isInProgress: false, myChannelSettings: {}, dragTimeout: null}
    }

    openModal = (evt) => {
        evt.preventDefault();
        this.setState({showModal: true})
    };

    closeModal = () => {
        this.setState({showModal: false})
    };

    toggleCookieChannel = (channel) => {
        if (this.isChannelIncluded(channel)) {
            removeFromIncludeChannels(channel)
        } else {
            addToIncludeChannels(channel)
        }
        ToastUtil.success("Inställningarna har uppdaterats");
        this.setActiveChannels();
        this.forceUpdate();
    };

    onChannelSettingsUpdate = (myChannelSettings, reloadCachedChannels) => {
        if (isLoggedIn()) {
            this.setState({myChannelSettings: myChannelSettings}, this.setActiveChannels);
        } else {
            this.setActiveChannels(this.forceUpdate);
        }
        if (reloadCachedChannels) {
            this.loadCachedChannels();
        }
    };

    isChannelIncluded = (channel) => {
        if (isLoggedIn()) {
            let {includeChannelIds} = this.getMyChannelSettings();
            if (_.size(includeChannelIds) > 0) {
                return includeChannelIds.indexOf(channel.id) >= 0;
            }
        } else {
            return isChannelIncluded(channel)
        }
    };

    getMyChannelSettings = () => {
        return this.state.myChannelSettings || {};
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    getLoader = () => {
        if (this.state.isInProgress) {
            return <div className="row">
                <div className="col-12 text-center">
                    <img src={loader}/>
                </div>
            </div>
        }
    };

    gotoTop = (index) => {
        if (swap(this.state.activeChannels, index - 1, index)) {
            this.saveChannelsOrder();
        }
    };

    gotoBottom = (index) => {
        if (swap(this.state.activeChannels, index + 1, index)) {
            this.saveChannelsOrder();
        }
    };

    getOnlyActiveChannels = () => {
        return _.filter(this.state.channels, (channel) => this.isChannelIncluded(channel))
    };

    setActiveChannels = (callback) => {
        this.setState({
            activeChannels: _.sortBy(
                _.filter(this.state.channels, (channel) => this.isChannelIncluded(channel)),
                "tabIndex"
            )
        }, callback)
    };

    saveChannelsOrder = () => {
        let orders = _.map(this.state.activeChannels, (channel, index) => {
            return {channelId: channel.id, tabIndex: index};
        });
        if (isLoggedIn()) {
            saveMyChannelsOrder({
                orders: orders
            }, (myChannelSettings) => {
                this.onChannelSettingsUpdate(myChannelSettings)
            });
        } else {
            addChannelsOrder(orders);
            this.forceUpdate();
        }
    };

    toggleIncludeChannel = (channel) => {
        if (isLoggedIn()) {
            http.post("/api/channels/toggleIncludeChannel", {includeChannelId: channel.id})
                .then((response) => {
                    let {myChannelSettings} = response.data.content;
                    this.onChannelSettingsUpdate(myChannelSettings)
                })
                .catch((error) => {
                    console.log(error.toJSON)
                })
        } else {
            if (_.size(getIncludeChannels()) === 0) {
                _.forEach(_.filter(this.state.channels, "default"), (channel) => {
                    addToIncludeChannels(channel);
                })
            }
            this.toggleCookieChannel(channel)
        }
    };

    _getChannelsOrder = () => {
        if (isLoggedIn()) {
            return this.getMyChannelSettings().orders;
        } else {
            return getChannelsOrder()
        }
    };

    loadCachedChannels = () => {
        this.toggleInProgress(true);
        http.get("/api/channels/cache" + toQueryString({active: true}))
            .then((response) => {
                let {channels} = response.data.content;
                if (!isLoggedIn()) {
                    if (_.size(getIncludeChannels()) === 0) {
                        _.forEach(channels, (channel) => {
                            if (channel.default) {
                                addToIncludeChannels(channel)
                            }
                        })
                    }
                }

                this.setState({
                    channels: channels
                }, () => {
                    this.setActiveChannels();
                });
            })
            .catch((error) => {
                console.log(error.toJSON)
            })
            .finally(() => {
                this.toggleInProgress(false)
            })
    };

    onChannelsDrag = (newChannels) => {
        this.setState({activeChannels: newChannels});
        if (this.state.dragTimeout !== null) {
            clearTimeout(this.state.dragTimeout)
        }
        this.state.dragTimeout = setTimeout(() => {
            this.setState({dragTimeout: null});
            this.saveChannelsOrder();
        }, 250);
    };

    componentDidMount() {
        if (isLoggedIn()) {
            getMyChannelSettings((myChannelSettings) => {
                this.onChannelSettingsUpdate(myChannelSettings);
                this.loadCachedChannels();
            });
        } else {
            this.loadCachedChannels();
        }
    }

    render() {
        let channels = this.state.activeChannels;
        _.forEach(channels, (channel, index) => {
            channel.index = index
        });
        doUpdateChannelsOrder(channels, this._getChannelsOrder());
        channels = _.sortBy(channels, "tabIndex");
        console.log("dfadsfdsfas")
        return (

            <div className={"main-content"}>
                <div className="container">
                    <UserMenu/>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-9 col-lg-9 list-container">
                            <h2>Välj startsidans kanaler</h2>
                            <p>Här ändrar du ordningen på kanalerna du har på startsidan.
                                Dra och flytta kanalerna med hjälp av musen eller med hjälp av pilarna.
                                För att ta bort en kanal, tryck på on knappen.</p>

                            <div className="row">
                                <div className="col-12 add-to-list">
                                    <a href="" onClick={this.openModal}>+ LÄGG TILL KANAL</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <ul>{
                                        <RLDD items={channels} onChange={this.onChannelsDrag}
                                              itemRenderer={(channel) => {
                                                  let {index} = channel;
                                                  return <li key={index}>
                                                      <div className="actions">
                                        <span onClick={() => this.toggleIncludeChannel(channel)}
                                              className={`${!this.isChannelIncluded(channel) ? 'off' : ''} toggle-btn`}>
                                            {this.isChannelIncluded(channel) ? 'On' : 'Off'}
                                        </span>
                                                          <span className="goto-top" onClick={() => {
                                                              this.gotoTop(index)
                                                          }}><FaArrowUp/></span>
                                                          <span className="goto-bottom" onClick={() => {
                                                              this.gotoBottom(index)
                                                          }}><FaArrowDown/></span>
                                                      </div>
                                                      <FaBars/>
                                                      {getChannelLogo(channel, "channel-logo")}
                                                      <span className="name">{channel.name}</span>
                                                  </li>
                                              }}/>
                                    }
                                    </ul>
                                    {this.getLoader()}
                                </div>
                            </div>
                            <AddToList show={this.state.showModal}
                                       isChannelIncluded={this.isChannelIncluded}
                                       toggleIncludeChannel={this.toggleIncludeChannel}
                                       onChannelSettingsUpdate={this.onChannelSettingsUpdate}
                                       onHide={this.closeModal} channels={this.state.channels}/>
                        </div>

                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <SideBar/>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default MyChannels
