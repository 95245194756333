import React from 'react';
import {Link} from 'react-router-dom';
import {FaUser} from 'react-icons/fa';
import {FaLock} from 'react-icons/fa';
import IsLoggedIn from '../components/user/IsLoggedIn';
import IsNotLoggedIn from '../components/user/IsNotLoggedIn';
import './header/Header.scss'
import IsAdmin from "./user/IsAdmin";
import {isFilmPage, isSeriesPage, isSportsPage} from "../utils/Util";
import {getParams, toQueryString} from "../utils/UrlUtil";
import _ from 'lodash'
import Login from "./user/Login";

class DesktopNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLogin: false
        }
    }

    openLogin = (evt) => {
        evt.preventDefault();
        this.setState({showLogin: true})
    };

    closeLogin = () => {
        this.setState({showLogin: false})
    };

    getUrlWithParams = (baseUrl) => {
        let urlParams = getParams();
        let params = {};
        if (urlParams.get("day")) {
            params.day = urlParams.get("day")
        }
        if (urlParams.get("week")) {
            params.week = urlParams.get("week")
        }
        if (_.size(params) > 0) {
            return baseUrl + toQueryString(params)
        }
        return baseUrl;
    };

    render() {
        let {showLogin} = this.state;
        return (
            <div>
                <nav className="navbar destopNav">

                    <a className="home" href="/Standard" title="Dagenstv - Tips från oss på filmer">Hem</a>
                    <a className="film-selected" href={this.getUrlWithParams("/Film")} title="Dagenstv - Film">Film</a>
                    <a className="series-selected" href={this.getUrlWithParams("/Tv-serier")}
                       title="Dagenstv - TV-Serier">Serier</a>
                    <a className="sport-selected" href={this.getUrlWithParams("/Sport")}
                       title="Dagenstv - Sport">Sport</a>
                    <a className="" href="/Artiklar" title="Dagenstv - Artiklar">Artiklar</a>
                    <span className="user-link">

                    <a href="/MinaKanaler" className="user-link">
                        <IsLoggedIn>
                            <FaUser/>
                        </IsLoggedIn>
                        <IsNotLoggedIn>
                            <FaLock/>
                        </IsNotLoggedIn>
                        <span>Min sida</span>
                    </a>
                    <span className="dropdown">
                            <IsLoggedIn>
                                <Link to="/logout">Logout</Link>
                            </IsLoggedIn>
                            <IsNotLoggedIn>
                                <a href="/login" onClick={this.openLogin}>Login</a>
                            </IsNotLoggedIn>
                        </span>
                    </span>
                </nav>
                {/* <InfoBar/> */}
                <Login show={showLogin} onHide={this.closeLogin}/>
            </div>
        )
    }
}

export default DesktopNavigation