import React from "react";
import '../MainContent.scss';
import '../common.scss';
import {
    addMyPrograms,
    downloadFile,
    getChannelLogo, getCurrentUser,
    getDay,
    getMyPrograms,
    getSelectedDay,
    getSelectedWeek,
    isLoggedIn,
    removeMyPrograms
} from "../../utils/Util";
import {FaCalendarAlt, FaHeart, FaMinusCircle, FaPrint} from 'react-icons/fa';
import http from "../../services/HttpService";
import {getParams, redirectTo, toQueryString} from "../../utils/UrlUtil";
import _ from 'lodash'
import moment from 'moment'
import ProgramModalContext from '../context/ProgramModalContext';
import ToastUtil from "../../utils/ToastUtil";
import {format} from "../../utils/DateUtil";
import imdb from "../../images/icon_imdb.gif";
import tipafriend from "../../images/new/popup/icon_tipafriend.gif";
import AddToFavouriteOptionsModal from "./AddToFavouriteOptionsModal";
import loader from "../../images/loader.gif";
import SideBar from "../side-bar/SideBar";

class TellAFriend extends React.Component {

    constructor(props) {
        super(props);
        let user = getCurrentUser() || {};
        this.state = {
            programId: getParams("id"),
            tellToFriendData: {yourName: user.fullName, yourEmail: user.username},
            isInProgress: false
        }
    }

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    handleTellToFriendForm = (evt) => {
        let {tellToFriendData} = this.state;
        tellToFriendData[evt.target.name] = evt.target.value;
        this.setState({tellToFriendData: tellToFriendData})
    };

    submitTellToFriendForm = (evt) => {
        evt.preventDefault();
        this.toggleInProgress(true);
        let {programId} = this.state;
        let params = this.state.tellToFriendData;
        params.programId = programId;
        http.post("/api/users/tellToFriend", params)
            .then((response) => {
                let {status, errors} = response.data;
                if (status) {
                    ToastUtil.success("Skicka ditt meddelande till din vän.");
                } else {
                    ToastUtil.error(errors[0])
                }
            })
            .catch((error) => {
                console.log(error.toJSON)
            })
            .finally(() => {
                this.toggleInProgress(false)
            })
    };

    componentDidMount() {
    }

    render() {
        let {tellToFriendData} = this.state;
        return (
            <div className="main-content">

                <div className="container row">
                    <div className="col-12 col-sm-12 col-md-9 col-lg-9">
                        <div className="grey-bg">
                            <form onSubmit={this.submitTellToFriendForm} className={"form"}>
                                <div className="mail-to">
                                    <div className="row margin0">
                                    <div className="form-group">
                                        <label className="col-form-label"> Ditt namn:</label>
                                        <input type="text" title="Ditt namn" name="yourName"
                                               onChange={this.handleTellToFriendForm}
                                               value={tellToFriendData.yourName}
                                               className="form-control"
                                               id="yourName"/>
                                    </div>

                                    <div className="form-group ">
                                        <label className="col-form-label"> Din väns namn:</label>
                                        <input type="text" title="Din väns namn" name="friendName"
                                               onChange={this.handleTellToFriendForm}
                                               className="form-control"
                                               id="friendName"/>
                                    </div>
                                    </div>
                                  
<div className="row margin0">
<div className="form-group ">
                                        <label className="col-form-label"> Din email:</label>
                                        <input type="email" title="Din email" name="yourEmail"
                                               value={tellToFriendData.yourEmail}
                                               onChange={this.handleTellToFriendForm}
                                               className="form-control"
                                               id="yourEmail"/>
                                    </div>

                                    <div className="form-group">
                                        <label className="col-form-label"> Din väns email:</label>
                                        <input type="email" title="Din väns email" name="friendEmail"
                                               className="form-control"
                                               onChange={this.handleTellToFriendForm} id="friendEmail"/>
                                    </div>
</div>
                                   <div className="row margin0">
                                   <div className="form-group">
                                        <label className="col-form-label dblock"> Personligt meddelande:</label>
                                        <textarea name="message" rows="3" cols="40" id="message"
                                                  onChange={this.handleTellToFriendForm}
                                                  title="Personligt meddelande"></textarea>
                                    </div>
                                    
                                   </div>
                                   <div className="form-group">
                                        <input type="submit" className="btn btn-primary btn-sm" value="Skicka"/>
                                        &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                                    </div>
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                        <SideBar/>
                    </div>
                </div>
            </div>
        )
    }
}

export default TellAFriend;