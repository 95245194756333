import React from "react";
import '../MainContent.scss';
import '../common.scss';
import MyFavouritePrograms from "../program/MyFavouritePrograms";
import {isMobile, isTablet} from "react-device-detect";
import SideBarPrograms from "./SideBarPrograms";

class SideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }

    render() {
        let {myPrograms} = this.state;
        return (
            <div className="sidebar">

                <div className="">
                    {!(isMobile && !isTablet) ? <MyFavouritePrograms/> : ''}
                </div>
                {/*<div className="">*/}
                {/*    {!(isMobile && !isTablet) ? <SideBarPrograms heading={'Serietoppen'}*/}
                {/*                                                 text={'Mest populära TV-avsnitten just nu'}/> : ''}*/}
                {/*</div>*/}
                {/*<div className="">*/}
                {/*    {!(isMobile && !isTablet) ? <SideBarPrograms heading={'Filmtoppen'}*/}
                {/*                                                 text={'Mest populära filmer just nu'}/> : ''}*/}
                {/*</div>*/}
                {/*<div className="">*/}
                {/*    {!(isMobile && !isTablet) ? <SideBarPrograms/> : ''}*/}
                {/*</div>*/}
            </div>
        )
    }
}

export default SideBar;