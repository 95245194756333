import React, {Component, useEffect, useState} from "react";
import Slider from "react-slick";
import smapleImage from "../../images/sample.png";
import '../side-bar/slider.scss'
import {redirectTo, toQueryString} from "../../utils/UrlUtil";
import http from "../../services/HttpService";

const BlogRecommendations = (props) => {

    const [blogs, setBlogs] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const heading = props.heading || 'Artiklars';
    let slider;
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    // dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    infinite: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const next = () => {
        slider.slickNext();
    };

    const previous = () => {
        slider.slickPrev();
    };

    useEffect(() => {
        let params = {
            active: true,
            order: "desc",
            sort: "publishAt",
            size: 30,
            withContent: false,
            client: 'DAGENSTV'
        };
        if (props.category) {
            params.category = props.category;
        }

        if (props.featured) {
            params.featured = true;
        }

        if (props.order) {
            params.order = props.order
        }

        if (props.sort) {
            params.sort = props.sort
        }

        if (props.excludeId) {
            params.excludeId = props.excludeId;
        }

        http.get("/api/blogs" + toQueryString(params))
            .then((response) => {
                let {blogs, totalElements} = response.data.content;
                setBlogs(blogs);
                setTotalElements(totalElements);
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
            })
    }, []);

    if (blogs.length > 0) {
        return (
            <div className="sliderSection">
                <h2> {heading}</h2>
                <div className="homeSlider">
                    {
                        <Slider ref={c => (slider = c)} {...settings}>
                            {
                                blogs.map((blog, index) => {
                                    return (
                                        <div className="item" key={index}
                                             onClick={() => redirectTo('/artikel/' + blog.friendlyUrl)}>
                                            {/* <img src={blog.thumbnailUrl} className="image"/> */}
                                            <div className="blogImage"  style={{
                                                backgroundImage: `url(${blog.thumbnailUrl})`
                                            }}>
<div className="overlay">
                                                <h3>{blog.teaser}</h3>
                                                <span>Dagenstv | {blog.category.name}</span>
                                            </div>
                                            </div>
                                            
                                        </div>
                                    )
                                })
                            }

                        </Slider>
                    }

                    <div className="controls">
                <span className="previous-btn" onClick={previous}>

                </span>
                        <span className="next-btn" onClick={next}>

                </span>
                    </div>
                </div>
            </div>
        );
    } else {
        return <div/>;
    }

};


export default BlogRecommendations;