import React from 'react';
import MainContent from '../MainContent';
import Register from '../user/Register';
import Settings from '../userPages/Settings';
import FavouritePrograms from '../userPages/FavouritePrograms';
import {Route, Switch} from 'react-router-dom';
import PageContent from "../staticPages/Content";
import MyChannels from "../userPages/MyChannels";
import ForgotPassword from "../user/ForgotPassword";
import BlogListing from "../blog/BlogListing";
import BlogDetail from "../blog/BlogDetail";
import StreamList from "../stream/list/StreamList";
import StreamDetail from "../stream/StreamDetail";
import StreamHome from "../stream/home/StreamHome";
import ChannelList from "../channel/ChannelList";
import CategoryList from "../category/CategoryList";
import ResetPassword from "../user/ResetPassword";
import TellAFriend from "../program/TellAFriend";

class MainRoutes extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path={["/Contact", "/se/contact"]} render={() => {
                    return <PageContent type='DAGENSTV_CONTACT_US'/>
                }}/>
                <Route exact path={["/OmDagensTV"]} render={() => {
                    return <PageContent type='DAGENSTV_ABOUT_US'/>
                }}/>
                <Route exact path='/Help' render={() => {
                    return <PageContent type='DAGENSTV_HELP'/>
                }}/>
                <Route exact path={["/Annonsera", "/se/contact/ads"]} render={() => {
                    return <PageContent type='DAGENSTV_ADVERTISEMENT'/>
                }}/>
                <Route exact path='/Cookies' render={() => {
                    return <PageContent type='DAGENSTV_COOKIES'/>
                }}/>
                <Route exact path='/Sitemap' render={() => {
                    return <PageContent type='DAGENSTV_SITEMAP'/>
                }}/>
                <Route exact path={["/Artiklar", "/se/article"]} component={BlogListing}/>
                <Route exact path="/se/article" component={BlogListing}/>

                <Route exact path="/Stream/Home" component={StreamHome}/>
                <Route exact path="/Stream" component={StreamList}/>
                <Route exact path="/Stream/:streamType" component={StreamList}/>
                <Route path="/play/:friendlyUrl" component={StreamDetail}/>

                <Route exact path="/artikel/:friendlyUrl" component={BlogDetail}/>
                <Route exact path={['/register', "/se/login/create1.asp"]} exact={true} component={Register}/>
                <Route exact path={['/MinaKanaler', '/se/frontpage/channels.asp']} component={MyChannels}/>
                <Route exact path='/ResetPassword' component={ResetPassword}/>
                <Route exact path='/TellAFriend' component={TellAFriend}/>
                <Route exact path="/forgotPassword/:hash" component={ForgotPassword}/>
                <Route exact path='/ProgramJagFoljer' component={FavouritePrograms}/>
                <Route exact path={['/Installningar', '/se/login/mustlogin.asp', '/se/settings']} component={Settings}/>
                <Route path="/Sok" component={MainContent}/>
                <Route path="/MinTVSida" component={MainContent}/>
                <Route path={["/kanal/:channelFriendlyUrl", "/se/tv/:channelFriendlyUrl"]} component={MainContent}/>
                <Route exact path="/se/kanaler" component={ChannelList}/>
                <Route exact path="/se/kategorier" component={CategoryList}/>
                <Route exact path={["/se/frontpage", "/se/film", "se/sport", "/se/serier", "/se/movies"]}
                       component={MainContent}/>
                <Route path="/:type?" component={MainContent}/>
            </Switch>
        )
    }
}

export default MainRoutes;