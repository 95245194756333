import {isLoggedIn} from "../utils/Util";
import http from "./HttpService";
import _ from 'lodash'
import {getParams} from "../utils/UrlUtil";

export const CHANNELS_ORDER = "channelsOrder";
export const INCLUDE_CHANNELS = 'includeChannels';
let params = getParams();
export const getMyChannelSettings = (success, error) => {
    if (isLoggedIn()) {
        http.get("/api/public/channels/myChannelSettings")
            .then((response) => {
                let {myChannelSettings} = response.data.content;
                if (success) {
                    success(myChannelSettings || {})
                }
            })
            .catch(function (err) {
                if (error) {
                    error(err)
                }
            })
    } else {
        if (success) {
            success({})
        }
    }
};


export const isChannelIncluded = (channel) => {
    let includeChannels = getIncludeChannels();
    return includeChannels.filter((_channel) => _channel.id === channel.id).length > 0
};

export const getIncludeChannels = () => {
    return JSON.parse(localStorage.getItem(INCLUDE_CHANNELS)) || [];
};

export const getChannelsOrder = () => {
    return JSON.parse(localStorage.getItem(CHANNELS_ORDER)) || [];
};

export const removeFromIncludeChannels = (channel) => {
    let channels = getIncludeChannels();
    _.remove(channels, {id: channel.id});
    localStorage.setItem(INCLUDE_CHANNELS, JSON.stringify(channels));
};

export const addToIncludeChannels = (channel) => {
    let includeChannels = getIncludeChannels();
    includeChannels.push({id: channel.id});
    localStorage.setItem(INCLUDE_CHANNELS, JSON.stringify(includeChannels));
};

export const clearIncludeChannels = () => {
    localStorage.setItem(INCLUDE_CHANNELS, JSON.stringify([]));
};

export const clearChannelsOrder = () => {
    localStorage.setItem(CHANNELS_ORDER, JSON.stringify([]));
};

export const addChannelsOrder = (orders) => {
    localStorage.setItem(CHANNELS_ORDER, JSON.stringify(orders));
};

export const saveMyChannelSettings = (params, success, error, always) => {
    http.post("/api/channels/saveMyChannelSettings", params)
        .then((response) => {
            let {myChannelSettings} = response.data.content;
            if (success) {
                success(myChannelSettings)
            }
        })
        .catch(error)
        .finally(always)
};

export const saveMyChannelsOrder = (params, success, error) => {
    http.post("/api/channels/saveMyChannelsOrder", params)
        .then((response) => {
            let {myChannelSettings} = response.data.content;
            if (success) {
                success(myChannelSettings)
            }
        })
        .catch(error)
};

export const doUpdateChannelsOrder = (channels, channelsOrder) => {
    if (_.size(channels) > 0) {
        _.forEach(channels, (channel) => {
            _.forEach(channelsOrder, (order) => {
                if (order.channelId === channel.id) {
                    channel.tabIndex = order.tabIndex;
                }
            })
        })
    }
};

export const getSelectedCategoryId = () => {
    return params.get("category");
};

export const getSelectedChannelId = () => {
    return params.get("channel");
};
export const isCategorySelected = () => {
    return params.has("category") || params.has("cat")
}
export const isChannelSelected = () => {
    return params.has("channel") || params.has("cha")
}

export const getSelectedStartTime = () => {
    let startTime = params.get("sta");
    if (!startTime) {
        startTime = params.get("startTime")
    }
    return startTime;
};
