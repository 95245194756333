import React from "react";
import "../ChannelList.scss";
import _ from "lodash";
import {getChannelUrl, getProgramClasses, isCompactView} from "../../utils/Util";
import ProgramModalContext from "../context/ProgramModalContext";

class Programs extends React.Component {
    static contextType = ProgramModalContext;

    constructor(props) {
        super(props);
        this.state = {
            size: props.initSize,
            onlyNonExpired: props.onlyNonExpired,
            rowScrollMargin: 0,
        };
    }

    getPrograms = (size, onlyNonExpired) => {
        let {programs} = this.props;

        if (_.size(programs)) {
            if (onlyNonExpired) {
                programs = programs.filter((program) => {
                    return !program.expired;
                });
            }
            if (size > 0) {
                return _.sortBy(programs.slice(0, size), ["startTime"]);
            }
            return _.sortBy(programs, ["startTime"]);
        }
        return [];
    };

    viewAll = () => {
        let {initSize} = this.props;
        if (this.state.size > 0) {
            this.setState({size: 0, onlyNonExpired: false});
        } else {
            this.setState({size: initSize, onlyNonExpired: true});
        }
    };

    getViewAll = () => {
        let {channel} = this.props;
        if (!isCompactView()) {
            return <li className="viewAll">
                <a href={"/kanal/" + channel.friendlyUrl}> &#8250; Visa hela dagen</a>
            </li>
        }
        if (_.size(this.getPrograms(0)) > 0) {
            return (
                <li onClick={this.viewAll} className="viewAll">
                    <span> &#8250; Visa hela dagen</span>
                </li>
            );
        }
    };

    UNSAFE_componentWillMount() {
        this.setState({size: this.props.initSize});
    }

    render() {
        let {channel} = this.props;
        let {size, onlyNonExpired} = this.state;
        return (
            <ul>
                {this.getPrograms(size, onlyNonExpired).map((program, index) => {
                    return (
                        <li key={program.id}
                            className={`${getProgramClasses(program)}`}
                            title={`${program.formattedStartTime} ${program.name}`}>
                            <a href={getChannelUrl(channel, program)}>
                                <span className="time">{program.formattedStartTime}&nbsp;</span>
                                <h3>
                                    <span className="program"> {program.name}&nbsp;</span>
                                    <span className="status">{program.live ? "Live" : ""}</span>
                                </h3>
                            </a>
                        </li>
                    );
                })}
                {this.getViewAll()}
            </ul>
        );
    }
}

export default Programs;
