export const WEEKDAYS = {
    0: {
        name: "söndag"
    },
    1: {
        name: "måndag"
    },
    2: {
        name: "tisdag"
    },
    3: {
        name: "onsdag"
    },
    4: {
        name: "torsdag"
    },
    5: {
        name: "fredag"
    },
    6: {
        name: "lördag"
    }
};

export const FULL_VIEW = 'full';
export const COMPACT_VIEW = 'compact';
export const TOTAL_WEEK_DAYS = 7;
export const CONTENT_PAGES = [
    {name: 'About US', value: 'DAGENSTV_ABOUT_US'},
    {name: 'Help', value: 'DAGENSTV_HELP'},
    {name: 'Cookies', value: 'DAGENSTV_COOKIES'},
    {name: 'Advertisement', value: 'DAGENSTV_ADVERTISEMENT'},
    {name: 'Sitemap', value: 'DAGENSTV_SITEMAP'},
    {name: 'Terms', value: 'DAGENSTV_TERMS'}
];
export const CHANNEL_CHUNK_SIZE = 3;
export const BLOG_CHUNK_SIZE = 4;
export const TABLET_CHANNEL_CHUNK_SIZE = 3;
export const MOBILE_CHANNEL_CHUNK_SIZE = 4;
export const TABLET_BLOG_CHUNK_SIZE = 3;
export const DEFAULT_SIZE = 10;
export const DEFAULT_COUNTRY_CODE = "SE";
export const DEFAULT_STREAM_PROVIDERS = {
    'NETFLIX': {
        provider: 'netflix',
        name: 'Netflix'
    },
    'VIAPLAY': {
        provider: 'viaplay',
        name: 'Viaplay'
    },
    'HBO': {
        provider: 'hbo',
        name: 'HBO'
    },
    'HBO_MAX': {
        provider: 'hbomax',
        name: 'HBO Max'
    }
};
export const FULL_ACCESS_PERMISSION = 'FULL_ACCESS';
export const WRITE_BLOG_PERMISSION = 'WRITE_BLOG';
export const WRITE_STREAMLY_PERMISSION = 'WRITE_STREAMLY';

export const FEATURE_PERMISSIONS = [
    FULL_ACCESS_PERMISSION,
    WRITE_BLOG_PERMISSION,
    WRITE_STREAMLY_PERMISSION,
];