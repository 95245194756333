import  {ProgramDataStarted, ProgramDataRecieved, ProgramDataFailed }  from '../actions/constants';
const initailState = {
    loading: false,
    channelList : [],
    error: null
}
const ManageChannels = (state = initailState, action) => {
    switch(action.type){
        case ProgramDataStarted:{
            return{
                ...state,
                loading: true
            }
        }
        case ProgramDataFailed:{
            return{
                ...state,
                error: action.err,
                loading: false
            }
        }

        case ProgramDataRecieved:{
            console.log('^^^^^', action.data);
            return{
                ...state,
                channelList: action.data,
                loading: false
            }
        }
        default:{
            return state;
        }
    }
}

export default ManageChannels;
