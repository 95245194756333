import React from 'react';
import {Alert, Button, Form, Row} from 'react-bootstrap';
import '../Form.scss'
import http from "../../services/HttpService";
import SimpleReactValidator from 'simple-react-validator';
import loader from '../../images/loader.gif'
import {postLoginRegister} from "../../utils/Util";
import SideBar from "../side-bar/SideBar";

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            firstName: '',
            lastName: '',
            password: '',
            isInProgress: false,
            errors: [],
        };
        this.validator = new SimpleReactValidator({
            element: (message) => {
                return <span className="error">{message}</span>
            }
        });
    }

    changeHandler = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    doRegister = (event) => {
        event.preventDefault();
        if (this.validator.allValid() && !this.state.isInProgress) {
            this.toggleInProgress(true);
            http
                .post("/api/users/register", this.state)
                .then((response) => {
                    let {content, status, errors} = response.data;
                    if (status) {
                        localStorage.setItem("user", JSON.stringify(content.user));
                        localStorage.setItem("jtoken", content.token);
                        localStorage.setItem("isNewUser", true);
                        postLoginRegister("/");
                    } else {
                        this.setState({errors: errors})
                    }
                })
                .catch(() => {
                })
                .finally(() => {
                    this.toggleInProgress(false)
                })

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    render() {
        return (
            <div className={"main-content"}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-9 col-lg-9 register-form">
                            <Row>
                                <div className="col-md-12 text">
                                    <h2>Välkommen till dagensTV.com, Sveriges främsta tv-community!</h2>
                                    <p>
                                        Genom att bli medlem på dagensTV.com tar du första steget in i framtidens
                                        TV-portal. Du får tillgång till aktuella tablåer och redaktionellt innehåll som
                                        dagligen uppdateras och tillgängligt var du än befinner dig. Som medlem har du
                                        alltid full koll på de TV-program som intresserar dig. Medlemsskapet är gratis.
                                    </p><br/>
                                    <h2>Det här får du som medlem i dagensTV.com:</h2>
                                    <ul>
                                        <li>
                                            <strong>Personlig sida</strong>
                                            <div>Här skapar du din egen TV-tablå, helt anpassad efter ditt kanalutbud.</div>
                                        </li>
                                        <li>
                                            <strong>Favoriter / Min Sida</strong>
                                            <div>Här skapar du en lista med dina favoritprogram, praktiskt för att skriva ut och ha hemma på kylskåpet.</div>
                                        </li>
                                        <li>
                                            <strong>DagensTV.com i mobilen</strong>
                                            <div>Access till din personliga TV-sida via mobiltelefonen.</div>
                                        </li>
                                        <li>
                                            <strong>Redaktionell Innehåll</strong>
                                            <div>Artiklar om intressanta program, serier, filmer m.m</div>
                                        </li>
                                    </ul>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-6">
                                    <h2>Bli medlem</h2>
                                </div>
                            </Row>
                            <Form className="form" onSubmit={this.doRegister} method="POST">
                                <Row>
                                    <div className="col-md-6">
                                        <label>E-postadress *</label>
                                        <Form.Control type="email" name="username" onChange={this.changeHandler}
                                                      placeholder="E-postadress"/>
                                        {this.validator.message('username', this.state.username, 'required')}
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-6">
                                        <label>Förnamn *</label>
                                        <Form.Control name="firstName" onChange={this.changeHandler}
                                                      placeholder="Förnamn"/>
                                        {this.validator.message('firstName', this.state.firstName, 'required')}
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-6">
                                        <label>Efternamn *</label>
                                        <Form.Control name="lastName" onChange={this.changeHandler}
                                                      placeholder="Efternamn"/>
                                        {this.validator.message('lastName', this.state.lastName, 'required')}
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-6">
                                        <label>Lösenord *</label>
                                        <Form.Control name="password" type="password" onChange={this.changeHandler}
                                                      placeholder="Lösenord"/>
                                        {this.validator.message('password', this.state.password, 'required')}
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-6">
                                        <label>Bekräfta Lösenord *</label>
                                        <Form.Control name="confrimPassword" type="confrimPassword"
                                                      onChange={this.changeHandler}
                                                      placeholder="Bekräfta Lösenord"/>
                                        {this.validator.message('password', this.state.password, 'required')}
                                    </div>
                                </Row>
                                {
                                    this.state.errors.map((error, index) => {
                                        return <Alert key={index} variant={'danger'}>
                                            {error}
                                        </Alert>
                                    })
                                }
                                <Row>
                                    <div className="col-md-6">
                                        <Button variant="primary" type="submit">
                                            Skapa konto
                                        </Button>
                                        &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                                    </div>
                                </Row>
                            </Form>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <SideBar/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Register