import React from "react";
import '../MainContent.scss';
import '../common.scss';
import {
    addMyPrograms,
    downloadFile,
    getChannelLogo, getCurrentUser,
    getDay,
    getMyPrograms,
    getSelectedDay,
    getSelectedWeek,
    isLoggedIn,
    removeMyPrograms
} from "../../utils/Util";
import {FaCalendarAlt, FaHeart, FaMinusCircle, FaPrint} from 'react-icons/fa';
import http from "../../services/HttpService";
import {redirectTo, toQueryString} from "../../utils/UrlUtil";
import _ from 'lodash'
import moment from 'moment'
import ProgramModalContext from '../context/ProgramModalContext';
import ToastUtil from "../../utils/ToastUtil";
import {format} from "../../utils/DateUtil";
import imdb from "../../images/icon_imdb.gif";
import tipafriend from "../../images/new/popup/icon_tipafriend.gif";
import AddToFavouriteOptionsModal from "./AddToFavouriteOptionsModal";

class ProgramActions extends React.Component {

    constructor(props) {
        super(props);
        let user = getCurrentUser() || {};
        this.state = {
            canShowTipsToFriend: false,
            canShowAddToFavouriteOptions: false,
            tellToFriendData: {yourName: user.fullName, yourEmail: user.username},
            isInProgress: false
        }
    }

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    handleTellToFriendForm = (evt) => {
        let {tellToFriendData} = this.state;
        tellToFriendData[evt.target.name] = evt.target.value;
        this.setState({tellToFriendData: tellToFriendData})
    };

    submitTellToFriendForm = (evt) => {
        evt.preventDefault();
        this.toggleInProgress(true);
        let {program} = this.props;
        let params = this.state.tellToFriendData;
        params.programId = program.id;
        http.post("/api/users/tellToFriend", params)
            .then((response) => {
                let {status, errors} = response.data;
                if (status) {
                    this.toggleShowTipsToFriend();
                    ToastUtil.success("Skicka ditt meddelande till din vän.");
                } else {
                    ToastUtil.error(errors[0])
                }
            })
            .catch((error) => {
                console.log(error.toJSON)
            })
            .finally(() => {
                this.toggleInProgress(false)
            })
    };

    getCalendarEvent = () => {
        let {program} = this.props;
        http
            .get("/api/programs/calendar/" + program.id)
            .then((response) => {
                let {CalendarEvent} = response.data.content;
                downloadFile("CalendarEvent.ics", CalendarEvent, "text/calendar");
            }).catch((error) => {
            console.log(error)
        });
    };

    gotoIMDB = () => {
        let {program} = this.props;
        redirectTo("https://www.imdb.com/find?q=" + program.name, '_blank');
    };

    showAddToFavouriteOptions = () => {
        let {channel, program} = this.props;
        if (isLoggedIn() && program.seasonLabel) {
            this.setState({canShowAddToFavouriteOptions: true})
        } else {
            this.addProgram(program, channel, false);
        }
    };

    closeAddToFavouriteOptions = () => {
        this.setState({canShowAddToFavouriteOptions: false})
    };

    onFavouriteOptionsSelect = (option) => {
        let addSeries = false;
        let {channel, program} = this.props;
        switch (option) {
            case "SERIES":
                addSeries = true;
        }
        this.addProgram(program, channel, addSeries)
    };

    addProgram = (program, channel, series) => {
        if (isLoggedIn()) {
            let params = {programId: program.id, addSeries: series};
            http.get("/api/programs/addToMyPrograms" + toQueryString(params))
                .then((response) => {
                    let {status, errors} = response.data;
                    if (status) {
                        window.location.reload(false)
                    } else {
                        alert(errors[0])
                    }
                })
                .catch((error) => {
                    console.log(error.toJSON)
                })
        } else {
            addMyPrograms(program, channel);
            window.location.reload(false)
        }
    };

    toggleShowTipsToFriend = () => {
        this.setState({canShowTipsToFriend: !this.state.canShowTipsToFriend})
    };

    closeProgramDetail = () => {
        this.props.onHide()
    };

    componentDidMount() {
    }

    render() {
        let {channel, program} = this.props;
        let {canShowAddToFavouriteOptions} = this.state;
        return (
            <>
                <ul className="action-list">
                    <li onClick={() => {
                        this.showAddToFavouriteOptions()
                    }}><span><FaHeart/></span>
                    </li>
                    <li onClick={this.getCalendarEvent}><span><FaCalendarAlt/></span></li>
                    <li onClick={this.gotoIMDB}><span><img src={imdb}/></span></li>
                    <li onClick={() => {
                        this.toggleShowTipsToFriend()
                    }}><a href={`/TellAFriend?id=${program.id}`} target={"_blank"}><img src={tipafriend}/></a>
                    </li>
                </ul>
                <AddToFavouriteOptionsModal show={canShowAddToFavouriteOptions}
                                            channel={channel} program={program}
                                            onFavouriteOptionsSelect={this.onFavouriteOptionsSelect}
                                            onHide={this.closeAddToFavouriteOptions}/>
            </>
        )
    }
}

export default ProgramActions;