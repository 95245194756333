import React from 'react';
import { Link } from 'react-router-dom';
import './Weekdays.scss';
import './header/Header.scss';
import '../constants/constants'
import {COMPACT_VIEW} from "../constants/constants";
import {
    isCompactView,
    isFilmPage, isMyChannelPage,
    isMyTVShow, isSeriesPage,
    isSportsPage,
    setView
} from "../utils/Util";
import IsLoggedIn from "./user/IsLoggedIn";
import IsNotLoggedIn from "./user/IsNotLoggedIn";
import {redirectTo} from "../utils/UrlUtil";

class SubNav extends React.Component {
    selectView = (view) => {
        setView(view)
        if (isFilmPage() || isSportsPage() || isSeriesPage()) {
            window.location.reload();
        } else {
            redirectTo("/Standard")
        }
    }
    componentDidMount() {

    }

    render() {
        return (<>
                <div className="subnav">
                    <Link href="/" className={`dm_mmt ${!isCompactView() ? 'dm_mmt_sel' : ''}`} onClick={(e) => {
                        e.preventDefault();
                        this.selectView();
                    }}>Tablåer per kanal</Link>
                    <Link href="?view=compact" className={`dm_mmt ${isCompactView() ? 'dm_mmt_sel' : ''}`}
                       onClick={(e) => {
                           e.preventDefault();
                           this.selectView(COMPACT_VIEW);
                       }}>Komprimerad vy</Link>
                    <IsLoggedIn>
                        <a href="/MinTVSida" className={`dm_mmt ${isMyTVShow() ? 'dm_mmt_sel' : ''}`}>Min TV-sida</a>
                    </IsLoggedIn>
                    <IsNotLoggedIn>
                        <a href="/MinaKanaler" className={`dm_mmt ${isMyChannelPage() ? 'dm_mmt_sel' : ''}`}>Mina kanaler</a>
                    </IsNotLoggedIn>
                    <a href="/JustNu" className="dm_mmt">På TV just nu</a>
                </div>
            </>
        )
    }
}
export default SubNav