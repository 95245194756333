import React from 'react';
import {isAdmin} from "../../utils/Util";

class IsAdmin extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (isAdmin()) {
            return this.props.children
        }
        return null
    }
}

export default IsAdmin