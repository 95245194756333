import React from 'react'
import {
    getCurrentUser,
    getDay,
    getMyPrograms,
    getSelectedDay,
    getSelectedWeek,
    getType, isChannelDetailPage,
    isChannelSearchPage, isJustNow,
    isLoggedIn, isMyTVShow
} from "../../utils/Util";
import http from "../../services/HttpService";
import {getParams, toQueryString} from "../../utils/UrlUtil";
import moment from 'moment'
import 'moment/locale/sv'
import {TOTAL_WEEK_DAYS} from "../../constants/constants";
import _ from "lodash";

class PrintChannelPrograms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            channels: [],
            selectedDate: moment().add(getDay(), "days"),
        }
    }

    loadPrograms = (channelFriendlyUrl) => {
        let params = getParams();
        let selectedDay = getSelectedDay();
        let selectedWeek = getSelectedWeek();
        let day = (selectedWeek - 1) * TOTAL_WEEK_DAYS + selectedDay;
        params.set("day", day);
        params.set("active", true);
        params.set("channelFriendlyUrl", channelFriendlyUrl)
        http.get("/api/es/channels/listWithPrograms?" + params.toString())
            .then((response) => {
                let {channels} = response.data.content;
                this.setState({
                    channels: _.concat(this.state.channels, channels)
                });
            })
            .catch(function (error) {
                console.log(error.data)
            })
    };

    componentDidMount() {
        const {match: {params}} = this.props;
        this.loadPrograms(params.channelFriendlyUrl)
    }

    render() {
        let {channels, selectedDate} = this.state;
        let channel = channels[0] || {programs: []}
        return (
            <div className="main-content">
                <div className="container">
                    <h2>{channel.friendlyUrl} Program Tablå {selectedDate.format("dddd DD MMMM, yyyy")}</h2>
                    <table className="table">
                        <tr>
                            <th>Tid</th>
                            <th>Program</th>
                            <th>Kanal</th>
                        </tr>
                        {
                            channel.programs.map((program, index) => {
                                return <tr index={index}>
                                    <td>{program.formattedStartTime} - {program.formattedEndTime}</td>
                                    <td>{program.name}</td>
                                    <td>{channel.name}</td>
                                </tr>
                            })
                        }
                    </table>
                </div>
            </div>
        );
    }
}

export default PrintChannelPrograms