import React from 'react'
import IsLoggedIn from "../user/IsLoggedIn";
import LoginHeader from "../userPages/LoginHeader";
import {Alert, Button, Form, Row, Col} from 'react-bootstrap';
import loader from '../../images/loader.gif'
import http from "../../services/HttpService";
import SimpleReactValidator from 'simple-react-validator';
import ToastUtil from "../../utils/ToastUtil";
import SideBar from "../side-bar/SideBar";
import UserMenu from "./UserMenu";
import TimeSelector from "../misc/TimeSelector";
import {getCurrentUser} from "../../utils/Util";

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                username: '', firstName: '', lastName: '', password: '',
            }, isInProgress: false, errors: [],
        };

        this.validator = new SimpleReactValidator({
            element: (message) => {
                return <span className="error">{message}</span>
            }
        });
    }

    saveSettings = (evt) => {
        evt.preventDefault();
        let {user, isInProgress} = this.state;
        if (this.validator.allValid() && !isInProgress) {
            this.toggleInProgress(true);
            let params = {
                firstName: user.firstName,
                lastName: user.lastName,
                username: user.username,
                watchStartHour: user.watchStartHour,
                watchEndHour: user.watchEndHour
            };

            if (user.password) {
                params.password = user.password
            }
            http.put("/api/users/saveSettings", params)
                .then((response) => {
                    let {user} = response.data.content;
                    let currentUser = getCurrentUser()
                    currentUser.watchStartTime = user.watchStartTime
                    currentUser.watchStartHour = user.watchStartHour
                    currentUser.watchEndTime = user.watchEndTime
                    currentUser.watchEndHour = user.watchEndHour
                    localStorage.setItem("user", JSON.stringify(currentUser));
                    this.setState({
                        user: user
                    });
                    ToastUtil.success("Settings has been saved");
                })
                .catch((error) => {
                    console.log(error.toJSON)
                })
                .finally(() => {
                    this.toggleInProgress(false)
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    changeHandler = (event) => {
        let {user} = this.state;
        user[event.target.name] = event.target.value;
        this.setState({user: user});
    };

    onStartTimeChange = (option) => {
        let {user} = this.state;
        user.watchStartHour = option.value
        this.setState({user});
    }

    onEndTimeChange = (option) => {
        let {user} = this.state;
        user.watchEndHour = option.value
        this.setState({user});

    }

    componentDidMount() {
        this.toggleInProgress(true);
        http.get("/api/users/getSettings")
            .then((response) => {
                let {user = {}} = response.data.content;
                this.setState({
                    user: user
                });
            })
            .catch((error) => {
                alert("error in fetch settings")
            })
            .finally(() => {
                this.toggleInProgress(false)
            })
    }

    render() {
        return (<div className="main-content">
            <div className="container setting-page">
                <UserMenu/>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-9 col-lg-9">
                        <LoginHeader/>
                        <IsLoggedIn>
                            <div className="register-form">


                                <h2>Användarinställningssida</h2>

                                <h3>Välkommen till dagensTV.com, Sveriges främsta tv-community!</h3>
                                <p>

                                    Genom att bli medlem på dagensTV.com tar du första steget in i framtidens
                                    TV-portal. Du får
                                    redan idag tillgång till mer redaktionellt innehåll än på någon annan sajt,
                                    dagligen
                                    uppdaterat och tillgängligt var du än befinner dig. Som medlem har du alltid
                                    full koll på de
                                    TV-program som intresserar dig. Medlemsskapet är gratis.
                                </p>
                                <h4>Det här får du som medlem i dagensTV.com:</h4>
                                <ul>
                                    <li>
                                        <h4>Personlig sida</h4>
                                        Här skapar du din egen TV-tablå, helt anpassad för dina intressen och ditt
                                        kanalutbud.
                                    </li>
                                    <li>
                                        <h4>Bevakning</h4>
                                        Via bevakningstjänsten får du mejl när en av dina favoriter, ex en
                                        skådespelare eller
                                        regissör, medverkar i ett program. Det går också bra att lägga upp
                                        kategorier som
                                        Fotboll och Thriller eller lägga in din favoritserie.
                                    </li>
                                    <li>
                                        <h4>Favoriter</h4>
                                        Här skapar du en lista med dina favoritprogram, praktiskt för att skriva ut
                                        och ha hemma
                                        på kylskåpet.
                                    </li>
                                    <li>
                                        <h4>DagensTV.com i mobilen</h4>
                                        <p>Access till din personliga TV-sida via mobiltelefonen.</p>
                                    </li>
                                </ul>
                                <Form className="form" onSubmit={this.saveSettings} method="POST">
                                    <Row>
                                        <div className="col-md-6">
                                            <label>Förnamn *</label>
                                            <Form.Control name="firstName" onChange={this.changeHandler}
                                                          value={this.state.user.firstName}
                                                          placeholder="Förnamn"/>
                                            {this.validator.message('firstName', this.state.user.firstName, 'required')}
                                        </div>
                                    </Row>
                                    <Row>

                                        <div className="col-md-6">
                                            <label>Efternamn *</label>
                                            <Form.Control name="lastName" onChange={this.changeHandler}
                                                          value={this.state.user.lastName}
                                                          placeholder="Efternamn"/>
                                            {this.validator.message('lastName', this.state.user.lastName, 'required')}
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-6">
                                            <label>E-postadress *</label>
                                            <Form.Control name="username" type="email"
                                                          onChange={this.changeHandler}
                                                          value={this.state.user.username}
                                                          placeholder="E-postadress"/>
                                            {this.validator.message('username', this.state.user.username, 'required|email')}
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-6">
                                            <label>Lösenord *</label>
                                            <Form.Control name="password" type="password"
                                                          onChange={this.changeHandler}
                                                          placeholder="Lösenord"/>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={"col-md-6"}>
                                            <div className="card">
                                                <div className="card-header">
                                                    Tider
                                                </div>
                                                <div className="card-body">
                                                    <Row>
                                                        <div className={"col-md-12"}>
                                                            <label>Från</label>
                                                            <TimeSelector
                                                                selectedHour={this.state.user.watchStartHour}
                                                                onChange={this.onStartTimeChange}/>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div className={"col-md-12"}>
                                                            <label>Till</label>
                                                            <TimeSelector
                                                                selectedHour={this.state.user.watchEndHour}
                                                                onChange={this.onEndTimeChange}/>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div className={"col-md-12"}>
                                                            <span className="form-text text-muted">
                                                                På din tv-sida visas bara program som sänds mellan
                                                                de tider du angett.
                                                            </span>
                                                        </div>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                    {this.state.errors.map((error, index) => {
                                        return <Alert key={index} variant={'danger'}>
                                            {error}
                                        </Alert>
                                    })}
                                    <Row>
                                        <div className="col-md-12">
                                            <Button variant="primary" type="submit">
                                                Ändra
                                            </Button>
                                            &nbsp;{this.state.isInProgress ? <img src={loader}/> : ''}
                                        </div>
                                    </Row>
                                </Form>
                            </div>
                        </IsLoggedIn>

                    </div>

                    <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                        <SideBar/>
                    </div>
                </div>

            </div>
        </div>)
    }
}

export default Settings
