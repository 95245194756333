import React from 'react';
import {Modal} from 'react-bootstrap';
import {FaAngleLeft} from 'react-icons/fa';

import Search from '../Search'
import {isLoggedIn} from "../../utils/Util";
import {
    addToIncludeChannels, clearChannelsOrder, clearIncludeChannels,
    getMyChannelSettings,
    removeFromIncludeChannels,
    saveMyChannelSettings
} from '../../services/ChannelService'
import _ from 'lodash'
import ToastUtil from "../../utils/ToastUtil";

class AddToList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            myChannelSettings: []
        };
    }

    onSearchChange = (searchTerm) => {
        this.setState({
            searchTerm: searchTerm
        });
    };

    componentDidMount() {
        if (isLoggedIn()) {
            getMyChannelSettings((myChannelSettings) => {
                this.setState({myChannelSettings: myChannelSettings});
            });
        }
    }

    saveChannelSettings = (includeChannelIds, orders) => {
        let params = {includeChannelIds: includeChannelIds};
        params.orders = orders || null;
        saveMyChannelSettings(params, (myChannelSettings) => {
            this.setState({myChannelSettings: myChannelSettings});
            this.props.onChannelSettingsUpdate(myChannelSettings, true);
            ToastUtil.success("Inställningarna har uppdaterats");
        })
    };

    resetToDefaultChannelSettings = () => {
        let {channels} = this.props;
        if (isLoggedIn()) {
            this.saveChannelSettings([], [])
        } else {
            clearIncludeChannels();
            clearChannelsOrder();
            _.forEach(channels, (channel) => {
                if (channel.default) {
                    addToIncludeChannels(channel)
                }
            });
            this.props.onChannelSettingsUpdate(undefined, true)
        }
    };

    addAllToIncludeChannels = () => {
        let {channels} = this.props;
        if (isLoggedIn()) {
            let includeChannelIds = _.map(channels, "id");
            this.saveChannelSettings(includeChannelIds)
        } else {
            _.forEach(channels, (channel) => {
                addToIncludeChannels(channel)
            });
            this.props.onChannelSettingsUpdate(undefined)
        }

    };

    render() {
        let {searchTerm} = this.state;
        let {onHide, channels, toggleIncludeChannel, isChannelIncluded} = this.props;
        return (
            <Modal
                {...this.props}
                size="md"
                className="add-to-list-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className="float-right">
                        <Search onSearchChange={this.onSearchChange} searchTerm={searchTerm}/>
                        
                        </div>
                       
                        <h5><FaAngleLeft onClick={onHide}/>LÄGG TILL KANALER</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="top">
                        <span className="reset" onClick={this.resetToDefaultChannelSettings}>återställa</span>
                        <span className="reset"
                              onClick={this.addAllToIncludeChannels}>Välj alla&nbsp;|&nbsp;</span>
                        <h4>TV-kanaler</h4>
                    </div>
                    <ul>
                        {
                            channels
                                .filter((channel) => !searchTerm || channel.name.toLowerCase().indexOf(searchTerm) >= 0)
                                .map((channel, index) => {
                                    return <li key={index}>
                                        <div className="actions">
                                        <span onClick={() => toggleIncludeChannel(channel)}
                                              className={`${!isChannelIncluded(channel) ? 'off' : ''} toggle-btn`}>
                                            {isChannelIncluded(channel) ? 'On' : 'Off'}
                                        </span>
                                        </div>
                                        <span className="tv-logo"/>
                                        {channel.name}
                                    </li>
                                })
                        }
                    </ul>
                </Modal.Body>
            </Modal>
        )
    }
}

export default AddToList