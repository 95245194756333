import React from 'react';
import {NavLink} from 'react-router-dom';
import {
    isAboutPage,
    isAdvertisementPage,
    isContactPage,
    isCookiePage,
    isHelpPage,
    isSitemapPage
} from "../../utils/Util";

function StaticLinks() {
    return (
        <nav className="nav">
            <ul>
                <li>
                    <a className={`nav-link ${isAboutPage() ? 'active' : ''}`} target="_self" href="/OmDagensTV">Om Dagenstv</a>
                </li>
                <li>
                    <a className={`nav-link ${isHelpPage() ? 'active' : ''}`} target="_self" href="/Help">Hjälp</a>
                </li>
                <li>
                    <a className={`nav-link ${isAdvertisementPage() ? 'active' : ''}`} target="_self" href="/Annonsera">Annonsera</a>
                </li>
                <li>
                    <a className={`nav-link ${isContactPage() ? 'active' : ''}`} target="_self" href="/Contact">Kontakt</a>
                </li>
                <li>
                    <a className={`nav-link ${isCookiePage() ? 'active' : ''}`} target="_self" href="/Cookies">Cookies</a>
                </li>
                <li>
                    <a className={`nav-link ${isSitemapPage() ? 'active' : ''}`} target="_self" href="/Sitemap">Sitemap</a>
                </li>

            </ul>
        </nav>
    )
}


export default StaticLinks