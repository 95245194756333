import React, {useEffect, useState} from 'react';
import '../streamListing.scss';
import {
    getAdsLabel,
    getHeaderDesktopAds,
    getHighDensityDesktopAds,
    getHighDensityMobileAds,
    isStreamHomePage
} from "../../../utils/Util";
import {isDesktop, isMobileDevice, isTableDevice} from "../../../utils/DeviceUtil";
import StreamHomeBlock from "./StreamHomeBlock";
import Providers from "../Providers";
import _ from 'lodash'
import {Helmet} from "react-helmet";

const StreamHome = (props) => {

    let [providers, setProviders] = useState(_.range(0, 9).map(() => {
        return {name: undefined, label: undefined}
    }));

    const onProvidersLoaded = (providers) => {
        setProviders(_.orderBy(providers, "tabIndex").slice(0, 10));
    };

    const setTitleAndMetadata = () => {
        let title = 'Dagenstv - Swedish TV channels guide,listing to streaming and TV programs';
        let description = "Dagenstv är den kompletta TV-guiden med TV-tablåer för alla TV-kanaler som finns tillgängliga på den svenska marknaden. Med Dagenstv kan du skapa din personliga TV-tablå och alltid ha Dagenstv på vad du ska titta på. ";
        let keywords = "TV ,TV-tablå ,TV tablå ,TV idag ,TV guide ,TV-guide ,TV nu ,TV program ,TV-program ,Sport på TV, på tv nu,på tv idag,tv guide,tvguide,tv guiden,tv guide sverige, Dagenstv tv,TV-tablå, tv-tablå, tvtablå, tv-kanaler, filmer på tv, serier på tv, sport på tv, komplett tv-guide, tvguide, TV-guide, tv-guide,TV-tablåer, tvtablåer, Streaming, gratis streaming, gratis film, tablåer, SVT1,SVT2, TV3, TV4, Kanal5, TV6, TV8, Discovery, TV-listings. ";
        if (isStreamHomePage()) {
            title = 'Dagenstv - Alla streamingtjänster svenska - filmer och serier på nätet';
        }
        return <Helmet>
            <title>{title}</title>
            <meta name="keywords" content={keywords}/>
            <meta name="description" content={description}/>
        </Helmet>
    };

    useEffect(() => {
    }, []);


    return (
        <div className="stream-listing-page">
            {setTitleAndMetadata()}
            {getHighDensityDesktopAds()}
            {getHighDensityMobileAds()}
            {getHeaderDesktopAds()}
            {isTableDevice() ? <>{getAdsLabel()}<div id="cncpt-tab_lb1"></div> </>: ''}
            <Providers onProvidersLoaded={onProvidersLoaded}/>
            {
                _.size(providers) > 0 ? providers.map((provider, index) => {
                    return <>
                    <h2 className="headingWithBorder">{provider.label}</h2>
                    {provider.name ?
                        <StreamHomeBlock size={8} providers={provider.name} viewBtnLabel={provider.label}/> : null}
                    {index === 0 ? <div className="blog-ads">
                        {isDesktop() ? <div id="cncpt-lb2"></div> : ''}
                        {isTableDevice() ? <div id="cncpt-tab_lb2"></div> : ''}
                        {isMobileDevice() ? <div id="cncpt-mob2"></div> : ''}
                    </div> : null}
                    {index === 1 ? <div className="blog-ads">
                        {isDesktop() ? <div id="cncpt-lb3"></div> : ''}
                        {isTableDevice() ? <div id="cncpt-tab_lb3"></div> : ''}
                        {isMobileDevice() ? <div id="cncpt-mob3"></div> : ''}
                    </div> : null}
                    <br/>
                    </>
                }) : null
            }
        </div>
    )
};

export default StreamHome;