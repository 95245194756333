import React, {Component, useEffect, useState} from "react";
import Slider from "react-slick";
import smapleImage from "../../images/sample.png";
import '../side-bar/slider.scss'
import {redirectTo, toQueryString} from "../../utils/UrlUtil";
import http from "../../services/HttpService";
import _ from 'lodash'
import {getProgramImage} from "../../utils/Util";

const StreamCarousel = (props) => {

    const [programs, setPrograms] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const heading = props.heading || 'Also Liked';
    let slider;
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    // dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const next = () => {
        slider.slickNext();
    };

    const previous = () => {
        slider.slickPrev();
    };

    useEffect(() => {
        let params = {
            active: true,
            order: "desc",
            sort: "dateCreated",
            size: 50,
            excludeProgramsType: ["article", "episode"],
            seriesProgram: false
        };

        if (props.order) {
            params.order = props.order
        }

        if (props.sort) {
            params.sort = props.sort
        }

        if (props.seasonFriendlyUrl) {
            params.seasonFriendlyUrl = props.seasonFriendlyUrl;
            params.seriesProgram = true;
        }

        if (_.size(props.providers) > 0) {
            params.providers = props.providers;
        }

        if (_.size(props.genres) > 0) {
            params.genres = props.genres;
        }

        if (props.size) {
            params.size = props.size;
        }

        if (props.country) {
            params.country = props.country;
        }

        http.get("/api/es/programs" + toQueryString(params))
            .then((response) => {
                let {programs, totalElements} = response.data.content;
                setPrograms(programs);
                setTotalElements(totalElements);
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
            })
    }, []);

    if (programs.length > 0) {
        return (
            <div className="sliderSection">
                <h2> {heading}</h2>
                <div className="homeSlider">
                    {
                        <Slider ref={c => (slider = c)} {...settings}>
                            {
                                programs.map((program, index) => {
                                    return (
                                        <div className="item" key={index}
                                             onClick={() => redirectTo('/play/' + program.friendlyUrl)}>
                                            <img src={getProgramImage(program)} className="image"/>
                                            <div className="overlay">
                                                <h3>{program.title}</h3>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </Slider>
                    }

                    <div className="controls">
                <span className="previous-btn" onClick={previous}>

                </span>
                        <span className="next-btn" onClick={next}>

                </span>
                    </div>
                </div>
            </div>
        );
    } else {
        return <div/>;
    }

};


export default StreamCarousel;