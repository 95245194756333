import React, {useEffect, useState} from 'react';
import Blogs from "./Blogs";
import './blogListing.scss';
import {getParams, toQueryString} from "../../utils/UrlUtil";
import http from "../../services/HttpService";
import {getAdsLabel, getHeaderDesktopAds} from "../../utils/Util";
import {isTableDevice} from "../../utils/DeviceUtil";
import {Helmet} from "react-helmet";

const BlogListing = () => {

        let params = getParams();
        const [categories, setCategories] = useState([]);
        const selectedCategory = params.get("category") || "Genre";
        const selectedSorting = params.get("sorting") || "latest";

        const setTitleAndMetadata = () => {
            return <Helmet>
                <title>{'TV-GUIDE | ARTIKLAR | DagensTv.com'}</title>
                <meta name="description"
                      content='Sveriges tv guide. Film, sport och tv serier. tvtablå, tvguide med 150 kanaler '/>
            </Helmet>

        };
        const loadCategories = () => {
            let params = {
                active: true
            };

            http
                .get("/api/blogCategories" + toQueryString(params))
                .then((response) => {
                    let {categories} = response.data.content;
                    setCategories(categories);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                });
        };

        const onGenreChange = (e) => {
            let params = getParams();
            params.set("page", 0);
            if (!isNaN(e.target.value)) {
                params.set("category", e.target.value);
            } else {
                params.delete("category");
            }
            window.location.href = "/Artiklar?" + params.toString();
        };

        const selectSorting = (value) => {
            let params = getParams();
            params.set("sorting", value);
            window.location.href = "/Artiklar?" + params.toString();
        };

        const sortingOptions = (
            <div className="sorting">
          <span className={`${selectedSorting === "popularity" ? 'active' : ''}`}>
                <a href="/Artiklar?sorting=popularity" onClick={(e) => {
                    e.preventDefault();
                    selectSorting("popularity");
                }}>Popularitet</a>
          </span>
                <span className={`${selectedSorting === "latest" ? 'active' : ''}`}>
                <a href="/Artiklar?sorting=latest" onClick={(e) => {
                    e.preventDefault();
                    selectSorting("latest");
                }}> Senaste</a>
          </span>
                <span className={`${selectedSorting === "title" ? 'active' : ''}`}>
                <a href="/Artiklar?sorting=title" onClick={(e) => {
                    e.preventDefault();
                    selectSorting("title");
                }}> A-Ö</a>
            </span>
            </div>
        );

        const genre = (
            <div className="genre">
                <form>
                    <select onChange={onGenreChange} value={selectedCategory}>
                        <option value={"Genre"}>All Genre</option>
                        {
                            categories.map((category, index) => {
                                return <option value={category.id} key={index}>{category.name}</option>
                            })
                        }
                    </select>
                </form>
            </div>
        );

        useEffect(() => {
            loadCategories();
        }, []);

        return (
            <div className="blog-listing-page">
                {setTitleAndMetadata()}
                {getHeaderDesktopAds()}
                {isTableDevice() ? <>{getAdsLabel()}<div id="cncpt-tab_lb1"></div> </>: ''}
                <div className="blog-container">
                    <div className="headingH2">
                        Artiklar
                    </div>
                    <div className="topSection">
                        {sortingOptions}
                        {genre}
                    </div>
                    <Blogs sorting={selectedSorting}/>
                </div>

            </div>
        )
    }
;

export default BlogListing;