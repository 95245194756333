import React from "react";
import "./filterBar.scss";
import "../../constants/constants";
import http from "../../services/HttpService";
import _ from 'lodash';
import {getParams, toQueryString} from "../../utils/UrlUtil";
import {getStreamProviders, hasProviderSelected, setStreamProviders} from "../../utils/Util";

class StreamGenre extends React.Component {
    constructor() {
        super();
        let params = getParams();
        this.state = {
            genres: [],
            selectedGenre: params.get("genre") || "Genre"
        };
    }

    loadGenres = () => {
        let params = {
            active: true
        };

        http
            .get("/api/es/genres" + toQueryString(params))
            .then((response) => {
                let {genres} = response.data.content;
                this.setState({genres});
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
            });
    };

    onGenreChange = (e) => {
        let params = getParams();
        params.set("page", 0);
        if ("Genre" != e.target.value) {
            params.set("genre", e.target.value);
        } else {
            params.delete("genre");
        }
        window.location.href = window.location.pathname + "?" + params.toString();
    };

    componentDidMount() {
        this.loadGenres();
    }

    render() {
        let {genres, selectedGenre} = this.state;
        return (
            <div className="genre">
                <form>
                    <select onChange={this.onGenreChange} value={selectedGenre}>
                        <option value={"Genre"}>Alla genre</option>
                        {
                            genres.map((genre, index) => {
                                return <option value={genre.name} key={index}>{genre.name}</option>
                            })
                        }
                    </select>
                </form>
            </div>
        );
    }
}

export default StreamGenre;
