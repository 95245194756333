import React from "react";
import '../MainContent.scss';
import '../common.scss';
import {
    getChannelLogo,
    getDay,
    getMyPrograms,
    getSelectedDay,
    getSelectedWeek,
    isLoggedIn,
    removeMyPrograms
} from "../../utils/Util";
import {FaMinusCircle, FaPrint} from 'react-icons/fa';
import http from "../../services/HttpService";
import {toQueryString} from "../../utils/UrlUtil";
import _ from 'lodash'
import moment from 'moment'
import ProgramModalContext from '../context/ProgramModalContext';
import ToastUtil from "../../utils/ToastUtil";

class SideBarPrograms extends React.Component {
    static contextType = ProgramModalContext;

    constructor(props) {
        super(props);
        this.smallLogos = require.context('../../images/logos/small', true, /.png$/);
        this.state = {
            myPrograms: [],
            isInProgress: false,
            selectedDate: moment(),
        }
    }


    getSmallLogo = (sourceId) => {
        try {
            return this.smallLogos('./' + sourceId + ".png").default
        } catch (e) {

        }
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    loadMyPrograms = () => {
        if (isLoggedIn()) {
            let params = {day: getDay()};
            http.get("/api/public/programs/me" + toQueryString(params))
                .then((response) => {
                    this.setState({
                        myPrograms: response.data.content.programs
                    });
                })
                .catch((error) => {
                    console.log(error.toJSON)
                })
                .finally(() => {
                })
        } else {
            this.setState({
                myPrograms: getMyPrograms()
            });
        }
    };


    componentDidMount() {
    }

    render() {
        let {myPrograms} = this.state;
        let {heading, text} = this.props;
        myPrograms = _.sortBy(myPrograms, "startTime");
        return (

            <div className="my-list">
                <h2>{heading}</h2>
                <p>{text}</p>
                <ul>
                    {
                        myPrograms.map((program, index) => {
                            return <li className="clearfix" key={index}>
                                <span className="remove-icon">
                                    <FaMinusCircle onClick={() => {
                                        this.removeProgram(program)
                                    }}/>
                                </span>
                                <span className=''>
                                    {/*<img src={this.getSmallLogo(program.channel.sourceId)}/>*/}
                                    {getChannelLogo(program.channel, "channel-logo")}
                                </span>
                                <span className="name" onClick={() => {
                                    this.context.onProgramDetailOpen(program, program.channel)
                                }}>{program.formattedStartTime} {program.name}</span>
                            </li>
                        })
                    }
                </ul>
            </div>

        )
    }
}

export default SideBarPrograms;