import React from 'react';
import {isArticleDetailPage, isArticleListPage, isStreamDetail, isStreamPage} from "../utils/Util";

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {searchTerm: props.searchTerm || '', placeholder: 'Sök'};
        this.divRef = React.createRef();
    }

    onInputChange = (e) => {
        this.setState({searchTerm: e.target.value});
        if (this.props.onSearchChange) {
            this.props.onSearchChange(e.target.value)
        }
    };

    onFormSubmit = (e) => {
        e.preventDefault();
        this.props.onFormSubmit(this.state.searchTerm);
    };

    addActive = () => {
        this.divRef.current.classList.add('search-active')
    };

    componentDidMount() {
        if (this.state.searchTerm) {
            this.addActive();
        }
        if (isArticleDetailPage() || isArticleListPage()) {
            this.setState({placeholder: "Sök Artiklar"})
        } else if (isStreamDetail() || isStreamPage()) {
            this.setState({placeholder: "Sök Film, Serier"})
        }
    }

    render() {
        let {placeholder} = this.state;
        return (
            <>
                <div  ref={this.divRef} className="search" onClick={this.addActive}>
                    <form onSubmit={this.onFormSubmit} className="form-inline">
                        <input className="form-control" type="search" placeholder={placeholder}
                               value={this.state.searchTerm} aria-label="search" onChange={this.onInputChange}/>
                        <button className="btn" type="submit">Sök</button>
                        {/* <span className="icon ml-auto">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-search"
                                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                <path fillRule="evenodd"
                                      d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                            </svg>
                        </span> */}
                    </form>

                </div>
            </>
        )
    }
}

export default Search;