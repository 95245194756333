import React from 'react';
import IsNotLoggedIn from '../user/IsNotLoggedIn';
import Login from '../user/Login';
import {Link} from 'react-router-dom';


class LoginHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <IsNotLoggedIn>
                <div className="non-logged">
                    <h2>Logga in</h2>
                    <p>
                        Du måste logga in för att komma åt denna tjänst.
                    </p>
                    <p>
                        Loggar in gör du genom att ange användarnamn och lösenord i rutan till höger i menyn ovan, under
                        rubriken "logga in".
                    </p>
                    <p>
                        Om du inte tidigare skapat en personlig tv-guide hos DagensTv.com kan du göra det här.
                    </p>
                </div>
            </IsNotLoggedIn>
        )
    }
}

export default LoginHeader