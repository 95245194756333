// import React, {useEffect, useState} from 'react';
// import './Footer.scss';
// import InfoBar from "../InfoBar";
// import http from "../../services/HttpService";
// import {toQueryString} from "../../utils/UrlUtil";
// import {getChannelLogo} from "../../utils/Util";
// import IsLoggedIn from "../user/IsLoggedIn";


// function Footer() {
//     const [channels, setChannels] = useState([]);
//     const [categories, setCategories] = useState([]);

//     let loadChannels = () => {
//         let params = {
//             active: true,
//             order: "asc",
//             sort: "tabIndex",
//             size: 5
//         };

//         http.get("/api/channels" + toQueryString(params))
//             .then((response) => {
//                 let {channels} = response.data.content;
//                 setChannels(channels);
//             })
//             .catch((error) => {
//                 console.log(error)
//             })
//     }

//     let loadCategories = () => {
//         http.get("/api/categories" + toQueryString({size: 1000}))
//             .then((response) => {
//                 let {categories} = response.data.content;
//                 setCategories(categories);
//             })
//             .catch((error) => {
//                 console.log(error.toJSON)
//             })
//     }

//     useEffect(() => {
//         loadChannels();
//         loadCategories();
//     }, []);
//     return (
//         <div>
//             <footer className="footer">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-xs-4">
//                             <h2>Sitemap</h2>
//                             <div>
//                                 <h3><a href="/Standard">Hem</a></h3>
//                                 <h3><a href="/Sport">Sport</a></h3>
//                                 <h3><a href="/Tv-serier">Serier</a></h3>
//                                 <h3><a href="/Film">Film</a></h3>
//                             </div>
//                             <div>
//                                 <h3><a href="/register">Bli medlem</a></h3>
//                                 <h3><a href="/Installningar">Inställningar</a></h3>
//                                 <IsLoggedIn>
//                                     <h3><a href="/logout">logga ut</a></h3>
//                                 </IsLoggedIn>
//                             </div>

//                         </div>
//                         <div className="col-xs-4">
//                             <h2>Snabbval</h2>
//                             <InfoBar categories={categories}/>
//                         </div>
//                         <div className="col-xs-4">
//                             <div className="channels-logo">
//                                 <h2>
//                                     Svenska kanaler</h2>
//                                 <div>
//                                     {
//                                         channels.map((channel) => {
//                                             return <a href={"/kanal/" + channel.friendlyUrl}
//                                                       title={`Tv-program på ${channel.name}`}>
//                                                 {getChannelLogo(channel, "channel-logo")}
//                                             </a>
//                                         })
//                                     }
//                                     <br/>
//                                     <a href="/se/kanaler/">Samtliga kanaler</a>
//                                     <br/>
//                                     <a href="/se/kategorier/">Samtliga kategorier</a>
//                                 </div>

//                             </div>
//                         </div>
//                         <div className="col-xs-4">
//                             <h2>Övrigt</h2>
//                             <a href="/Standard">DagensTV.com som startsida</a> <br/>
//                             <a href="/Contact" class="folink">Kontakta
//                                 DagensTV.com</a><br/>
//                             <a href="/Annonsera" class="folink">Annonsera på DagensTV.com</a><br/>
//                             <a href="/Annonsera" class="folink">Annonsera i nätverket TV-guiderna</a>
//                         </div>

//                     </div>
//                 </div>
//                 <div className="copyright">
//                     <div className="row">
//                         <div className="col-xs-12 col-sm-12"><p>© Dagenstv 2020. Alla rättigheter förbehållna.</p></div>
//                     </div>
//                 </div>

//             </footer>
//         </div>

//     )
// }

// export default Footer


import React from 'react';
import './Footer.scss';
import StaticLinks from './StaticLinks';

function Footer() {
    return (
        <div>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-4 ">
                            <h2>Om Dagenstv.com</h2>
                            <p>Sveriges bästa TV-tablå heter dagenstv.com. Här hittar du en enkel tv-guide som ger dig
                                full
                                översikt över vilka program, film och serier som visas på Sveriges TV-kanaler. Vår
                                tv-guide
                                drivs inom Comspace group, som också ligger bakom streamingguiden <a
                                    href="https://www.streamly.com/" target="_blank"></a></p>
                            {/* <StaticLinks/> */}
                        </div>
                        <div className="col-xs-12">
                            <h2>Våra kategorier:</h2>
                            <div className="row">
                                <div className="col-xs-4 col-sm-4">
                                    <h3><a href="/Sport">Sport</a></h3>
                                    <p>Vad vore livet utan sport och genom vår snabba filitering får du snabbt en
                                        överblick kring
                                        varje dags olika sportsädningar.</p>
                                </div>
                                <div className="col-xs-4 col-sm-4">
                                    <h3><a href="/Tv-serier">Serier</a></h3>
                                    <p>I denna kategori hittar du en stort utbud på olika serie, som våra TV-kanaler
                                        sänder varje
                                        dag. Från reality till drama, nya till gamla serier.</p>

                                </div>
                                <div className="col-xs-4 col-sm-4">
                                    <h3><a href="/Film">Film</a></h3>
                                    <p>Här har vi samlat en översikt över vilka filmer du kan se varje dag. Du kan också
                                        få tips på
                                        filmer genom att läsa våra populära artiklar.</p>
                                </div>

                                <div className="col-xs-4 col-sm-4">
                                    <h3><a href="https://streamly.com" target="_blank">Play</a></h3>
                                    <p>Senaste tillskottet på vår TV-guide är ett bibliotek med Sveriges största alla
                                        streamingtjänster. Hitta vart du kan titta på filmer och serier online – den
                                        ultimata guiden.</p>

                                </div>
                                <div className="col-xs-4 col-sm-4">
                                    <h3><a href="/Annonsera">Annonsera</a></h3>
                                    <p>Är du intresserad att synas och annonsera på dagenstv.com? Vi har många spännande
                                        upplägg och
                                        möjligheter.</p>
                                </div>
                                <div className="col-xs-4 col-sm-4">
                                    <h3><a href="/Contact">Kontakt</a></h3>
                                    <p>Om det är något du undrar över eller vill fråga kan du använda det här formuläret
                                        för att kontakta oss.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <p className="copyright">© dagenstv.com 2020. Alla rättigheter förbehållna.</p> */}
                </div>
                <div className="copyright">
                    <div className="row">
                        <div className="col-xs-7 col-sm-7">
                            <StaticLinks/>
                        </div>
                        <div className="col-xs-5 col-sm-5"><p>© dagenstv.com 2023. Alla rättigheter förbehållna.</p>
                        </div>
                    </div>
                </div>

            </footer>
        </div>

    )
}

export default Footer   