import React from 'react';
import SideBar from '../side-bar/SideBar'
import {toQueryString} from "../../utils/UrlUtil";
import http from "../../services/HttpService";
import {isContactPage} from "../../utils/Util";
import StaticLinks from "../footer/StaticLinks";

class PageContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: undefined,
            message: null,
            error: null,
            contactForm: {}
        }
    }

    changeHandler = (evt) => {
        let {contactForm} = this.state;
        contactForm[evt.target.name] = evt.target.value;
        this.setState({contactForm})
    };

    onSubmit = (e) => {
        e.preventDefault();
        let {contactForm} = this.state;
        contactForm.client = "DagensTV"
        this.setState({error: null, message: null})
        http
            .post("/api/emails/newEnquiry", contactForm)
            .then((response) => {
                let {message} = response.data.content;
                let {status, errors} = response.data;
                if (status) {
                    this.setState({message})
                } else {
                    this.setState({error: errors[0]})
                }
            })
            .catch((error) => {
                console.log(error.toJSON())
            })
    };

    componentDidMount() {
        let params = {contentType: this.props.type};
        http
            .get("/api/contents" + toQueryString(params))
            .then((response) => {
                let {pages} = response.data.content;
                let page = pages[0] || {};
                this.setState({content: page.content});
            })
            .catch((error) => {
                console.log(error.toJSON())
            })
    }

    render() {
        let {message, error, content} = this.state;
        return (
            <div className="main-content">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 col-lg-3 sidebar-nav">
                            <div className="padding0">
                                <StaticLinks/>
                            </div>
                        </div>
                        <div className="col-sm-9 col-lg-9 static-content">
                            <div dangerouslySetInnerHTML={{__html: content}}/>
                            {isContactPage() ? <div className="row">
                                    <div className="col-md-6">
                                        <form className="register-form" onSubmit={this.onSubmit}>
                                            <div>
                                                <label htmlFor="name">Ärende</label>
                                                <input type="text" className="form-control"
                                                       onChange={this.changeHandler}
                                                       id="name" name="name" aria-describedby="name"
                                                       placeholder="Ärende"/>
                                            </div>
                                            <div>
                                                <label htmlFor="from">E-post</label>
                                                <input type="email" className="form-control" id="from" name="from"
                                                       onChange={this.changeHandler}
                                                       aria-describedby="email"
                                                       placeholder="E-post"/>
                                            </div>
                                            <div>
                                                <label htmlFor="body">Meddelande</label>
                                                <textarea name="body"
                                                          onChange={this.changeHandler}
                                                          className="form-control" col="2" row="2"> </textarea>
                                            </div>
                                            <button type="submit" className="btn btn-primary">SKICKA</button>
                                        </form>
                                        {message ? <p>{message}</p> : null}
                                        {error ? <p className={"error"}>Error: {error}</p> : null}
                                    </div>

                                </div>
                                : null}
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default PageContent