import React from "react";
import http from "../../services/HttpService";
import {toQueryString} from "../../utils/UrlUtil";

class CategoryList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: []
        };
    }

    locaCategories = () => {
        http.get("/api/categories" + toQueryString({active: true, size: 1000}))
            .then((response) => {
                let {categories} = response.data.content;
                this.setState({categories});
            }).catch((error) => {
            console.log(error)
        });
    }

    componentDidMount() {
        this.locaCategories();
    }

    render() {
        let {categories} = this.state;
        return (
            <div className="channel-list">
                <div className="col-12 col-sm-12 col-md-12 col-lg-9 paddingLeft0">
                <h2>Kategorier</h2>
                <ul>
                    {
                        categories.map((category, index) => {
                            return <li>
                                <a href={"/Standard?category=" + category.id}
                                   title={category.name}>{category.name}</a>
                            </li>
                        })
                    }
                </ul>
                </div>
            </div>
        )
    }
}

export default CategoryList;
