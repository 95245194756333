import React from 'react';
import {Alert, Button, Form} from 'react-bootstrap';
import '../Form.scss'
import http from "../../services/HttpService";
import SimpleReactValidator from 'simple-react-validator';
import loader from '../../images/loader.gif'
import ToastUtil from "../../utils/ToastUtil";
import {toQueryString} from "../../utils/UrlUtil";
import SideBar from "../side-bar/SideBar";

class RestPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            isInProgress: false,
            errors: []
        };
        this.validator = new SimpleReactValidator({
            element: (message) => {
                return <span className="error">{message}</span>
            }
        });
    }

    changeHandler = (event) => {
        this.state[event.target.name] = event.target.value;
        this.forceUpdate();
    };

    sendForgotPasswordMail = (event) => {
        event.preventDefault();
        let {email} = this.state;
        if (email) {
            this.toggleInProgress(true);
            http.get("/api/users/dagenstv/forgotPassword" + toQueryString({email: email}))
                .then(response => {
                    this.setState({message: 'Skickade glömt lösenord mail till ' + email});
                })
                .catch(error => {
                    ToastUtil.error(error.response.data.errors[0]);
                })
                .finally(() => {
                    this.toggleInProgress(false);
                });
        }
    };

    toggleInProgress = (bool) => {
        this.setState({isInProgress: bool});
    };

    render() {
        let {email, message} = this.state;
        return (
            <div className="main-content">


                <div className="container row">
                    <div className="col-12 col-sm-12 col-md-9 col-lg-9">
                        <div className="grey-bg">


                            <p>Vi kunde inte hitta något konto med det användarnamn och lösenord du angett.
                                <br/><br/>

                                Om du glömt ditt lösenord, fyll i din e-post address nedan och klicka på OK så skickar
                                vi det
                                till dig.
                            </p>
                            <Form className="form col-6" onSubmit={this.sendForgotPasswordMail} method="POST">
                                <Form.Group>
                                    <Form.Label>E-postadress</Form.Label>
                                    <Form.Control type={"email"} name="email" value={email}
                                                  onChange={this.changeHandler}/>
                                    {this.validator.message('email', email, 'required')}
                                    {message ? <Alert variant={'success'}>
                                        {message}
                                    </Alert> : null}
                                </Form.Group>
                                <Form.Group>
                                    <Button variant="primary" type="submit" className="cyan-background">
                                        Skicka
                                        &nbsp;{this.state.isInProgress ?
                                        <img src={loader}/> : ''}
                                    </Button>
                                </Form.Group>
                            </Form>
                            <p>
                                Tänk på att du måste ange samma e-post address som du angav när du skapade din
                                personliga
                                tv-guide hos DagensTv.com.
                                <br/><br/>

                                Om du inte tidigare skapat en personlig tv-guide hos DagensTv.com kan du göra det här.
                            </p>

                        </div>

                    </div>
                    <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                        <SideBar/>
                    </div>

                </div>
            </div>
        )
    }
}

export default RestPassword