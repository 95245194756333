import React, {useEffect, useState} from "react";
import {getParams, redirectTo} from "../../../utils/UrlUtil";
import http from "../../../services/HttpService";
import "../streamListing.scss";
import {BLOG_CHUNK_SIZE, TABLET_BLOG_CHUNK_SIZE} from "../../../constants/constants";
import _ from 'lodash'
import {isTablet} from "react-device-detect";
import {ellipsis, getProgramImage, getSelectedCountry} from "../../../utils/Util";
import UserAction from "../UserAction";
import StreamCard from "../StreamCard";
import StreamPlay from "../StreamPlay";


const StreamHomeBlock = (props) => {
    const DEFAULT_STREAM_SIZE = 8;
    const [programs, setPrograms] = useState([]);
    const [favourites, setFavourites] = useState([]);
    const [showPlay, setShowPlay] = useState(false);
    const [selectedProgram, setSelectedProgram] = useState({});
    const [totalElements, setTotalElements] = useState(0);
    const size = props.size || DEFAULT_STREAM_SIZE;
    const providers = props.providers;
    let screenWidth = window.screen.width;
    const blogChunkSize = isTablet && screenWidth >= 768 && screenWidth <= 970 ? TABLET_BLOG_CHUNK_SIZE : BLOG_CHUNK_SIZE;

    const onPlay = (program) => {
        setSelectedProgram(program);
        setShowPlay(true)
    };

    const closePlay = () => {
        setShowPlay(false);
    };

    const loadFavourites = () => {
        http
            .get("/api/es/favourites/kollatv/me")
            .then((response) => {
                let {favourites} = response.data.content;
                setFavourites(favourites || []);
            })
            .catch((error) => {
                console.log(error);
            })
    };


    const loadPrograms = () => {
        let params = getParams();
        params.set("providers", providers);
        params.set("active", true);
        params.set("sort", "recentlyAdded");
        params.set("order", "desc");
        params.set("size", size);
        params.set("excludeProgramsType", ["article", "episode"]);
        params.set("country", getSelectedCountry());
        http
            .get("/api/es/programs?" + params.toString())
            .then((response) => {
                let {programs, totalElements} = response.data.content;
                setPrograms(programs);
                setTotalElements(totalElements);
            })
            .catch((error) => {
                console.log(error);
            })
    };

    const getFavouriteByProgramId = (programId) => {
        if (_.size(favourites) > 0) {
            return _.find(favourites, ["programId", programId])
        }
    };

    useEffect(() => {
        for (let i = 1; i <= 40; i++) {
            programs.push({category: {}})
        }
        setPrograms(programs);
        loadFavourites();
        loadPrograms();
    }, []);

    return (
        <div>

            {
                _.chunk(programs, blogChunkSize).map((programsSubList, parentIndex) => {
                    return <div key={parentIndex}>
                        <ul className="stream-listing">
                            {
                                programsSubList.map((program, index) => {
                                    return (
                                        <li className="item cursor-pointer" key={index}
                                            onClick={() => redirectTo('/play/' + program.friendlyUrl)}>
                                            <StreamCard program={program} onPlay={() => {
                                                onPlay(program)
                                            }} favourite={getFavouriteByProgramId(program.id)}/>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                })
            }
            <a href={"/Stream?providers=" + props.providers} className="view-all-btn">View All {props.viewBtnLabel} </a>
            <StreamPlay show={showPlay} onHide={closePlay} program={selectedProgram}/>

        </div>
    );
};

export default StreamHomeBlock;
